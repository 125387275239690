import React, { useRef, useState, useContext } from "react";
import {
  cancelFileUpload,
  postAudienceFile,
} from "../../../actions/OnBoardingActions";
import { FILE_TYPE_UNKNOWN, FAIL_STATUS } from "../../../api/constants";
import { OnBoardingContext } from "../../Pages/OnBoarding";
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import "./FileUpload.css";
import MatchKeys from "./MatchKeys";

const FileUpload = () => {
  const widjetProps = useContext(OnBoardingContext);
  const inputFile = useRef(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [columnMappingData, setColumnMappingData] = useState({});
  const [fileLabel, setFileLabel] = useState(null);
  const [showFileFormatWindow, setShowFileFormatWindow] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const onFileChange = async (event, fromDragAndDrop = false) => {
    setPageLoader(true);
    const formData = new FormData();
    formData.append("file", fromDragAndDrop ? event[0] : event.target.files[0]);
    formData.append("file_id", 0);
    formData.append("job_id", 0);
    let fileName = "";
    if (fromDragAndDrop) {
        fileName = event[0].name;
        setFileLabel(event[0].name);
    } else {
        fileName = event.target.files[0].name;
        setFileLabel(event.target.files[0].name);
    }
    postAudienceFile(formData).then((res) => {
      widjetProps.clearSavedConfig();
      setPageLoader(false);
      if (res === FILE_TYPE_UNKNOWN) {
          widjetProps.handleUnkownFileTypeModel(true);
          setFileLabel(null);
      } else if (res.status === FAIL_STATUS) {
          if (!res.data) {
              widjetProps.handleFileUploadErrors(`Invalid parameter: The file ${fileName} is not allowed. Allowed file extensions are csv, tsv, log, txt`);
          } else {
              widjetProps.handleFileUploadErrors(res.data);
          }
          setFileLabel(null);
      } else if (res) {
        setColumnMappingData(res);
        widjetProps.handleColumnMappingModel(true, true, res);
      }
      setPageLoader(false);
      clearFileInput();
    });
  };

  const onFileUpload = () => {
    inputFile.current.click();
  };

  const clearFileInput = () => {
    document.getElementById("column-file").value = "";
  };

  const editColumnMapping = () => {
    widjetProps.handleColumnMappingModel(true, false, columnMappingData);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      onFileChange(files, true);
    }
  };
    const viewSampleFileFormat = (e) => {
        setViewMode(true);
        setShowFileFormatWindow(!showFileFormatWindow);
    };

    const handleUnkownFileTypeModel = (isOpen = false) => {
        setViewMode(false);
       setShowFileFormatWindow(isOpen)
    };

  return (
      <div className={"file-upload-outer-div"}>
          <div>
              <input
                  type="file"
                  id="column-file"
                  ref={inputFile}
                  accept=".csv,.log,.txt,.tsv"
                  onChange={onFileChange}
                  style={{ display: "none" }}
              />
              <div
                  className="upload-drop-area"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                  onClick={onFileUpload}
              >
                  {!pageLoader && !fileLabel && <span style={{height:"2.5rem"}}>
                          <i className="icon-upload upload-icon" />
                          <Label className="upload-label">Click To Select File or Drop File Here</Label>
                      </span>
                  }
                  {!pageLoader && fileLabel && <span style={{height:"2.5rem"}}>
                      <i className="icon-file file-icon" />
                      <Label className="upload-label">{fileLabel}</Label>
                    </span>
                  }
                  {pageLoader && <i className="fa fa-spinner fa-spin upload-loading-icon"aria-hidden="true"></i>}
              </div>
          </div>
          <div className="file-upload-buttons">
              <Button
                  className="view-mapping-button"
                  theme={"primary"}
                  type="button"
                  // className="btn btn-primary btn-sm"
                  disabled={!columnMappingData?.id}
                  onClick={editColumnMapping}
              >
                  View Mapping
              </Button>
              &nbsp;&nbsp;
              <Button
                  className="cancel-upload-button"
                  type="button"
                  // className="btn btn-secondary btn-sm"
                  disabled={!pageLoader}
                  onClick={() => {
                      setFileLabel(null);
                      cancelFileUpload();
                  }}
              >
                  Cancel Upload
              </Button>
              &nbsp;&nbsp;&nbsp;<Label className="upload-lower-label">Max File Size is <b>2 GB</b>. Allowed File Types
              are <b>csv</b>, <b>tsv</b>, <b>txt</b>, and <b>log</b> &nbsp;&nbsp;&nbsp;<a
                  href="#"
                  onClick={(e) => viewSampleFileFormat(e)}>View Sample File Format</a></Label>
          </div>
          <MatchKeys
              isOpenModel={showFileFormatWindow}
              viewMode={viewMode}
              // clearSavedConfig={clearSavedConfig}
              handleClose={() => handleUnkownFileTypeModel()}
          />
      </div>
  );
}

export default FileUpload;
