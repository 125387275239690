import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import gearIcon from "../images/gearIcon.PNG";
import {Button} from "@progress/kendo-react-buttons";
import React, {useEffect} from "react";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import "./IdxTagInstructions.css";
import impressionTrackerExample from '../images/ImpressionTrackerExample.png'
import {button} from "../../helpers/ActivateSegmentFormHelpers";
import categoryTagExample from '../images/categoryTagExample.png'

const IdxTagInstructions = (props) => {
    const {pixelTagId, setShowTagCode} = props;
    const loggedInFromToolbox = sessionStorage.getItem("isToolboxLogged");
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleSelectTab = e => {
        console.log(e)
        setSelectedTab(e.selected);
    };
    const closePixelDialog = () => {
        setShowTagCode(false);
    }
    function copyTag() {
        if(pixelTagId !== undefined){
            document.getElementById("url-copy-button").innerHTML = "Copied!"
            document.getElementById("script-copy-button").innerHTML = "Copy";
            // return navigator.clipboard.writeText("<img src=\""+ imgSrc + "\" alt=\"\" style=\"display:none !important;\" />");
            return navigator.clipboard.writeText(`https://idxgm.rtactivate.com/tagid/${pixelTagId}/?`);
        }else{
            document.getElementById("url-copy-button").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }
    function copyScript() {
        if (pixelTagId !== undefined) {
            document.getElementById("url-copy-button").innerHTML = "Copy"
            document.getElementById("script-copy-button").innerHTML = "Copied!";
            const scriptBlock = `<script src="https://unpkg.com/device-uuid@1.0.4/lib/device-uuid.min.js"></script>
                <script>
                if (typeof DeviceUUID !== "undefined") {
                    var uuid = new DeviceUUID().get();
                    var imgElement = document.querySelector("img[src^='https://idxgm.rtactivate.com/tagid/${pixelTagId}/']");
                    if (imgElement) {
                        if (imgElement.src.includes("device=")) {
                            imgElement.src = imgElement.src.replace("device=", "device=" + uuid);
                        } else {
                            imgElement.src = imgElement.src.replace("?", "?device=" + uuid);
                        }
                    }
                }
                </script>`;
            return navigator.clipboard.writeText(scriptBlock);
        } else {
            document.getElementById("script-copy-button").innerHTML = "Not Available";
            return navigator.clipboard.writeText("Not Available");
        }
    }

    function copyCategoryTag1() {
        if(pixelTagId !== undefined){
            document.getElementById("category-copy-button1").innerHTML = "Copied!"
            document.getElementById("category-copy-button2").innerHTML = "Copy"
            document.getElementById("category-copy-button3").innerHTML = "Copy"
            document.getElementById("category-copy-button4").innerHTML = "Copy"
            const scriptBlock = `https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=${"${CLIENT_ID}"}&device=${"${DEVICE}"}
&category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}`
            return navigator.clipboard.writeText(scriptBlock);
        }else{
            document.getElementById("category-copy-button1").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }
    function copyCategoryTag2() {
        if(pixelTagId !== undefined){
            document.getElementById("category-copy-button1").innerHTML = "Copy"
            document.getElementById("category-copy-button2").innerHTML = "Copied!"
            document.getElementById("category-copy-button3").innerHTML = "Copy"
            document.getElementById("category-copy-button4").innerHTML = "Copy"
            const scriptBlock = `<img id="idx-tag" src="https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id={{CLIENT_ID}}&
category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}" alt=""
style="display:none !important;" />

<script src="https://unpkg.com/device-uuid@1.0.4/lib/device-uuid.min.js"></script>
<script>
if (typeof DeviceUUID !== "undefined") {
var uuid = new DeviceUUID().get();
var imgElement = document.querySelector("img[id='idx-tag']");
if (imgElement.src.includes("&device=")) {
imgElement.src = imgElement.src.replace("&device=", "&device=" + uuid);
} else {
imgElement.src += "&device=" + uuid;
}
}
</script>`
            return navigator.clipboard.writeText(scriptBlock);
        }else{
            document.getElementById("category-copy-button2").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }

    function copyCategoryTag3() {
        if(pixelTagId !== undefined){
            document.getElementById("category-copy-button1").innerHTML = "Copy"
            document.getElementById("category-copy-button2").innerHTML = "Copy"
            document.getElementById("category-copy-button3").innerHTML = "Copied!"
            document.getElementById("category-copy-button4").innerHTML = "Copy"
            const scriptBlock =  `https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=${"${CAMPAIGN_ID}"}-${"${CACHEBUSTER}"}
&device=${"${DEVICE}"}&category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}`
            return navigator.clipboard.writeText(scriptBlock);
        }else{
            document.getElementById("category-copy-button3").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }
    function copyCategoryTag4() {
        if(pixelTagId !== undefined){
            document.getElementById("category-copy-button1").innerHTML = "Copy"
            document.getElementById("category-copy-button2").innerHTML = "Copy"
            document.getElementById("category-copy-button3").innerHTML = "Copy"
            document.getElementById("category-copy-button4").innerHTML = "Copied!"
            const scriptBlock =  `https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=%eadv!-%ebuy!-
%n&device=${"${MAID}"}&category1=%epid!&category2=%ecid!&category3=%u`
            return navigator.clipboard.writeText(scriptBlock);
        }else{
            document.getElementById("category-copy-button4").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }

    useEffect(() => {
        console.log(selectedTab)
    }, [selectedTab]);
    return (
        <div>
            <Dialog
                className={"idx-pixel-dialog dialog-standard"}
                title={"IDX Tag"}
                closeIcon={false}
                width={"55vw"}
                height={"80vh"}
            >
                {/*<div className={"pixel-dialog-text-container"}>*/}
                <TabStrip selected={selectedTab} onSelect={handleSelectTab} tabPosition={"top"} renderAllContent={true}>
                    <TabStripTab title="Site Tag"  className={"impression-tracker-tab"}>
                        <div>
                            Steps for Adding an Impression Tracker in <strong>{`The Trade Desk`}</strong>
                            <div className={"paragraph-standard"}>
                                <strong>{`STEP 1:`}</strong> Open The Trade Desk login page and sign in with your
                                credentials.
                            </div>

                            <div className={"paragraph-standard"}>
                                <strong>{`STEP 2:`}</strong> Navigate to the Creative Setup
                                <ul>
                                    <li>
                                        {`Go to `}<strong>{`Campaigns`}</strong>{` from the top menu.`}
                                    </li>
                                    <li>
                                        Select the campaign where you want to add the impression tracker.
                                    </li>
                                    <li>
                                        {`Click on the `}<strong>{`Creatives`}</strong>{` tab in the campaign view.`}
                                    </li>
                                    <li>
                                        Choose the creative you want to edit or add a new creative.
                                    </li>
                                </ul>
                            </div>

                            <div className={"paragraph-standard"}>
                                <div><strong>{`STEP 3:`}</strong> Locate the Tracking Section</div>
                                <div>&nbsp;&nbsp;&nbsp;&nbsp;{`In the `}<strong>{`Creative Details`}</strong>{` page, scroll down to find the `}<strong>{`Tracking`}</strong>{` section.`}
                                </div>
                            </div>

                            <div className={"paragraph-standard"}>
                                <strong>{`STEP 4:`}</strong> Add the 1x1 Pixel Impression Tracking URL
                                <div>&nbsp;&nbsp;&nbsp;&nbsp;{`Under the `}<strong>{`Tracking URLs`}</strong>{` section:`}
                                </div>
                                <ol>
                                    <li>
                                        {`Click on `}<strong>{`Add Tracking URL`}</strong> {`.`}
                                    </li>
                                    <li>
                                        {`Select `}<strong>{`Impression Tracking`}</strong> {` as the type.`}
                                    </li>
                                    <li>
                                        Paste the following
                                        URL: {`https://idxgm.rtactivate.com/tagid/`}<strong>{`${pixelTagId}`}</strong>{`/?`}
                                        <button id={"url-copy-button"} className={"btn btn-primary"}
                                                style={{padding: "0px 3px 0px 3px", fontSize: '14px'}}
                                                onClick={copyTag}>Copy
                                        </button>
                                    </li>
                                    {/*<li>*/}
                                    {/*    Save the changes.*/}
                                    {/*</li>*/}
                                </ol>
                            </div>

                            <div className={"paragraph-standard"}>
                                <strong>{`STEP 5:`}</strong> Add the JavaScript Tracking Tag (This code allows for
                                device IDs to be collected – A must
                                for any HCP Measurement)
                                <div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;If using JavaScript for impression tracking:
                                </div>
                                <ol>
                                    <li>
                                        {`Under the same `}
                                        <strong>{`Tracking`}</strong> {`section, look for a field or option labeled `}<strong>{`Custom
                                            JavaScript Tag`}</strong> {`or `}<strong>{`Impression Tracker`}</strong> {` .`}
                                    </li>
                                    <li>
                                        <div>Paste the following JavaScript tag in the field</div>
                                        <div className={"script-block"}>
                                        <pre>
                                            <code>
                                        {`<script src="https://unpkg.com/device-uuid@1.0.4/lib/device-uuid.min.js"></script>
<script>
if (typeof DeviceUUID !== "undefined") {
var uuid = new DeviceUUID().get();
var imgElement =
document.querySelector("img[src^='https://idxgm.rtactivate.com/tagid/`}<strong>{`${pixelTagId}`}</strong>{`/']");
if (imgElement.src.includes("device=")) {
imgElement.src = imgElement.src.replace("device=", "device=" + uuid);
} else {
imgElement.src = imgElement.src.replace("?", "?device=" + uuid);
}
}
</script>`}</code>
                                        </pre>
                                            <button id={"script-copy-button"} className={"btn btn-primary"}
                                                    style={{
                                                        padding: "0px 3px 0px 3px",
                                                        fontSize: '14px',
                                                        float: 'right',
                                                        marginTop: "15px"
                                                    }}
                                                    onClick={copyScript}>Copy
                                            </button>
                                        </div>
                                    </li>
                                </ol>
                            </div>

                            <div className={"paragraph-standard"}>
                                <strong>{`STEP 6:`}</strong> Save and Apply the Tracker
                                <div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <strong>{`Once the tracking URLs and JavaScript are added:`}</strong>
                                    <ul>
                                        <li>
                                            {`Click `}<strong>{`Save`}</strong>{` to apply the changes.`}
                                        </li>
                                        <li>
                                            Ensure the tracker is associated with the creative in the campaign.
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className={"mt-4"}>
                                The result should look similar to the following image.
                            </div>
                            <div className={"screenshot-block"}>
                                <img src={impressionTrackerExample} alt="Impression Tracker"/>
                            </div>
                            <div className={"pixel-dialog-text pixel-dialog-text-3 mt-3"}>
                                {`To revisit this page later, go to the IDX Tag Manager table and click the `}
                                <img src={gearIcon}
                                     className={"idx-gear-icon"}/> {`icon in the Tag row. Select "Analytics Pixel Code".`}
                            </div>
                        </div>
                    </TabStripTab>

                    <TabStripTab title="Category Tag" className={"category-tab"} disabled={!loggedInFromToolbox}>
                        <div className={"paragraph-standard"}>
                                <span>Through ADS, a user can create an IDX Tag. A category tag will allow a client to add Macros which
                                will provide data points on a dynamic basis. These categories will allow a user to have up to 3
                                    more data points to segment the results by.</span>
                        </div>
                        <div className={""}>
                            <div className={"paragraph-standard tag-code"}>
                                    <pre>
                                    <code>
                                        {`https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=${"${CLIENT_ID}"}&device=${"${DEVICE}"}
&category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}`}
                                    </code>
                                </pre>
                            </div>
                            <button id={"category-copy-button1"} className={"btn btn-primary"}
                                    style={{padding: "0px 3px 0px 3px", fontSize: '14px'}}
                                    onClick={copyCategoryTag1}>Copy
                            </button>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Customize the IDX Pixel Tag for Your Needs</div>
                            <div>Adjust the pixel code as necessary to capture specific data points, such as user
                                interactions, page
                                views, or conversions.
                            </div>
                            <ul>
                                <li>
                                    Replace the placeholders ${"{CATEGORY1}"}, ${"{CATEGORY2}"}, and
                                    ${"{CATEGORY3}"} with
                                    the
                                    relevant parameters from your system. These parameters should be dynamically
                                    generated based on the specific context of the platform you are using.
                                </li>
                            </ul>
                            <div>
                                The replacements can be as follows:
                            </div>
                            <ul>
                                <li>
                                    <strong>Client_ID</strong>: Replace Client_ID with your ID (or leave blank).
                                </li>
                                <li>
                                    <strong>Device_ID</strong>: MAID (or leave blank).
                                </li>
                                <li>
                                    <strong>Category1</strong>: Identifier that you would use to create a custom
                                    segment.
                                </li>
                                <li>
                                    <strong>Category2</strong>: Identifier that you would use to create a custom
                                    segment.
                                </li>
                                <li>
                                    <strong>Category3</strong>: Identifier that you would use to create a custom
                                    segment.
                                </li>
                            </ul>
                            <div className={"mb-1"}>
                                If there are no parameters or macros to capture, replace Category1 with Brand Name
                            </div>
                            <div>
                                To ensure the URL is correctly formatted, you need to urlencode the values of the
                                parameters.
                                urlencode replaces spaces and special characters with their corresponding
                                percent-encoded
                                values.
                            </div>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Replace Macros with Parameters – Website Example</div>
                            <div className={"mb-1"}>
                                Replace the placeholders with the relevant parameters from your system. These
                                parameters
                                should be dynamically generated based on your platform's context:
                            </div>
                            <div>
                                Example URL Format Retailer:
                            </div>
                            <ul>
                                <li>
                                    Category1 = Brand (GAP, OLD_NAVY, BANANA_ REPUBLIC, ATHLETA)
                                </li>
                                <li>
                                    Category2 = Section (NEW, WOMEN, MEN, GIRLS, BOYS, SALE)
                                </li>
                                <li>
                                    Category3 =Product (JEANS, PANTS, DRESSES, ETC)
                                </li>
                            </ul>
                        </div>
                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Capture IP, Time Stamp and Parameters.</div>
                            <span>Replace the {} with macros. These should automatically be populated.</span>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Replace Macros with Parameters – Ad Server Example</div>
                            <div>Replace the placeholders with the relevant parameters from your system. These
                                parameters
                                should be dynamically generated based on your platform's context. The following are
                                examples on
                                what you can include:
                            </div>
                            <div className={"paragraph-standard tag-code"}>
                                    <pre>
                                        <code>
                                            {`<img id="idx-tag" src="https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id={{CLIENT_ID}}&
category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}" alt=""
style="display:none !important;" />

<script src="https://unpkg.com/device-uuid@1.0.4/lib/device-uuid.min.js"></script>
<script>
if (typeof DeviceUUID !== "undefined") {
var uuid = new DeviceUUID().get();
var imgElement = document.querySelector("img[id='idx-tag']");
if (imgElement.src.includes("&device=")) {
imgElement.src = imgElement.src.replace("&device=", "&device=" + uuid);
} else {
imgElement.src += "&device=" + uuid;
}
}
</script>`}
                                        </code>
                                    </pre>
                            </div>
                            <button id={"category-copy-button2"} className={"btn btn-primary"}
                                    style={{padding: "0px 3px 0px 3px", fontSize: '14px'}}
                                    onClick={copyCategoryTag2}>Copy
                            </button>
                            <ul>
                                <li>
                                    <strong>{pixelTagId}</strong> – This is the Custom Tag ID which Semcasting
                                    provides to associate tag
                                    results
                                    with a segment. (DO NOT CHANGE).
                                </li>
                                <li>
                                    <strong>id</strong> – The ID can be a unique ID provided by the client or it can
                                    be used to
                                    include other
                                    details. In this example we will inlcude
                                    ${"{CAMPAIGN_ID}"}-${"{CACHEBUSTER}"} (Add
                                    any
                                    additional macros separated by a dash -).
                                </li>
                                <li>
                                    <strong>device</strong> – Use the macro for Mobile Advertising ID (e.g.,
                                    ${"{MAID}"}).
                                </li>
                                <li>
                                    <strong>category1</strong> – Replace with the Placement ID (e.g.,
                                    ${"{PLACEMENT_ID}"}).
                                </li>
                                <li>
                                    <strong>category2</strong> – Replace with the Creative ID (e.g.,
                                    ${"{CREATIVE_ID}"}).
                                </li>
                                <li>
                                    <strong>category3</strong> – Replace with the Encoded Click URL (e.g.,
                                    ${"{CLICK_URL_ENC}"}).
                                </li>
                            </ul>
                            <div className={"paragraph-standard tag-code"}>
                                    <pre>
                                        <code>
                                            {`https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=${"${CAMPAIGN_ID}"}-${"${CACHEBUSTER}"}
&device=${"${DEVICE}"}&category1=${"${CATEGORY1}"}&category2=${"${CATEGORY2}"}&category3=${"${CATEGORY3}"}`}
                                        </code>
                                    </pre>
                            </div>
                            <button id={"category-copy-button3"} className={"btn btn-primary"}
                                    style={{padding: "0px 3px 0px 3px", fontSize: '14px'}}
                                    onClick={copyCategoryTag3}>Copy
                            </button>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>URL Encoding Requirement</div>
                            <div>Ensure that all parameter values are URL-encoded to replace spaces and special
                                characters with
                                their corresponding percent-encoded values.
                            </div>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Placing the Pixel in Campaign Manager 360</div>
                            <ol type={"1"}>
                                <li>
                                    Go to the <strong>Advertiser</strong>.
                                </li>
                                <li>
                                    Select <strong>Properties</strong>.
                                </li>
                                <li>
                                    Click on <strong>Event Tags</strong>.
                                </li>
                                <li>
                                    Navigate to <strong>Impression Event Tags – Advertiser Tags</strong>.
                                </li>
                                <li>
                                    Set the following properties:
                                </li>
                                <ol type={"a"}>
                                    <li><strong>Application</strong> – Select the appropriate option.</li>
                                    <li><strong>Enabled</strong> – Enable the tag.</li>
                                    <li><strong>Tag Type</strong> – Select Script.</li>
                                    <li><strong>Tag URL</strong> – Enter the URL in the following format:</li>
                                </ol>
                            </ol>
                            <div>Example with CM360 Macros</div>
                            <div className={"paragraph-standard tag-code"}>
                                    <pre>
                                        <code>
                                            {`https://idxgm.rtactivate.com/tagid/${pixelTagId}/?id=%eadv!-%ebuy!-
%n&device=${"${MAID}"}&category1=%epid!&category2=%ecid!&category3=%u`}
                                        </code>
                                    </pre>
                            </div>
                            <button id={"category-copy-button4"} className={"btn btn-primary"}
                                    style={{padding: "0px 3px 0px 3px", fontSize: '14px'}}
                                    onClick={copyCategoryTag4}>Copy
                            </button>
                            <div>
                                <img src={categoryTagExample} alt="categoryTag"/>
                            </div>
                            <ul>
                                <li>Set the Trigger:</li>
                                <ul>
                                    <li>Click Triggering and select where the tag should fire:</li>
                                    <ul>
                                        <li>For sitewide tracking, select All Pages.</li>
                                        <li>For specific pages, create a Page View Trigger and define the page URL
                                            or
                                            conditions.
                                        </li>
                                    </ul>
                                </ul>
                                <li>Save the Tag:</li>
                                <ul>
                                    <li>Name the tag (e.g., "Impression Tag") and click Save.</li>
                                </ul>
                                <li>Publish the Changes:</li>
                                <ul>
                                    <li>In the GTM dashboard, click Submit to publish your changes.</li>
                                </ul>
                                <li>Test the Tag:</li>
                                <ul>
                                    <li>Use GTM's Preview Mode to ensure the tag is firing correctly on the intended
                                        pages.
                                    </li>
                                    <li>Verify impressions in your analytics or ad platform.</li>
                                </ul>
                            </ul>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}>Test the Pixel by Informing Semcasting when Tag is
                                placed.
                            </div>
                            <div>After placing the IDX Pixel, notify Semcasting to ensure the pixel is correctly
                                firing. Testing is
                                essential to confirm data is being captured accurately. Semcasting will assist in
                                verifying the
                                pixel’s functionality.
                            </div>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}> Test the Creative Tracker by Informing Semcasting when the
                                Pixel Tag is
                                placed.
                            </div>
                            <div>After placing the IDX Creative Pixel, check your Dashboard in the next two hours to
                                see
                                if the tag is
                                firing and you are collecting impressions information. Please notify Semcasting in
                                case
                                the pixel is
                                not correctly firing. Semcasting will assist in verifying the pixel’s functionality.
                            </div>
                        </div>

                        <div className={"paragraph-standard"}>
                            <div className={"sub-title"}> Additional Technical Support</div>
                            <div>If you encounter any issues while placing Semcasting's IDX Creative Pixel or need
                                assistance with
                                troubleshooting, please follow these steps to contact our Technical Support team at
                                ADSSupport@semcasting.com.
                            </div>
                        </div>
                    </TabStripTab>

                </TabStrip>

                {/*</div>*/}

                <DialogActionsBar>
                    <div className={"idx-pixel-dialog-button-container"}>
                        <Button
                            className={"button-standard button-white"}
                            type={'button'}
                            disabled={false}
                            onClick={() => {
                                closePixelDialog();
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </DialogActionsBar>

            </Dialog>

        </div>
    )
}
export default IdxTagInstructions;