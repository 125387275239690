import React, {useEffect, useState} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import "./css/ads-global.css";
import "./css/custom.css";
import "./css/fontawesome/font-awesome.css";
// import ForgotPassword from "./components/Screens/ForgotPassword";
import Home from "./components/Screens/Home";
import HomeToolbox from "./components/Screens/HomeToolbox";
// import Admin from "./Admin/Admin";
import Dashboard from "./Admin/Pages/Dashboard";
import OnBoarding from "./Admin/Pages/OnBoarding";
import ContactUsPage from "./Admin/Pages/ContactUsPage";
import AudienceDesign from "./Admin/Pages/AudienceDesign";
import AudienceDesignEdit from "./Admin/Pages/AudienceDesignEdit";
import DataEnhancement from "./Admin/Pages/DataEnhancement";
import ActivateMedia from "./Admin/Pages/ActivateMedia";
// import LoginPage from "./components/Pages/LoginPage";

// import Attribution from "./Admin/Pages/Attribution";
// import AccountDetailsMediaMint from "./Admin/Pages/AccountDetailsMediaMint";
import Header from "./Admin/Components/Header";
// import {default as HeaderIfNoSession} from "./components/main-Header/Header";
import PrivateRoute from "./Utils/PrivateRoute";
// import SegmentationDashboard from "./Admin/Pages/SegmentationDashboard";
import TreeSegmentationDashboard from "./Admin/Pages/TreeSegmentationDashboard";
// import { isUserLoggedIn } from "./Utils/Common";
import AdminTabsPage from "./Admin/Pages/AdminTabsPage";
// import FileTransferSettingsPage from "./Admin/Pages/FileTransferSettingsPage";
import ActivateSegment from "./Admin/Pages/ActivateSegment";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
//import AccountsDetails from "./Admin/Setup/Pages/AccountsDetails";
//import AccountsDetailsSetup from "./Admin/Setup/Pages/AccountsDetailsSetupOLd2";
import CreateAccount from "./Admin/Setup/Pages/CreateAccount";
import AccountPricing from "./Admin/Setup/Pages/AccountPricing";
//import "../src/scss/new.css"
import BusinessDashboard from "./Admin/Pages/BusinessDashboard";
import UserSearch from "./Admin/Setup/Pages/UserSearch";
import SideNavigationBar from "./Admin/Components/SideNavigationBar";
import JobStatus from "./Admin/Setup/Pages/JobStatus";
import {
    ACTIVATE_FORM_ROUTE,
    ACTIVATE_SEGMENT_ROUTE,
    APP_STATUS_ROUTE,
    CONTACT_US_ROUTE,
    HELP_CENTER_ROUTE,
    JOB_STATUSES_ROUTE, SIGN_OUT_API,
} from "./api/constants";

import Billing from "./Admin/Setup/Pages/Billing";
import AppStatus from "./Admin/Setup/Pages/AppStatus";
import {isUserLoggedIn, removeDataFromStorage, semcastAxios} from "./Utils/Common";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import HelpCenterPage from "./Admin/Pages/HelpCenter";
import ResetPassword from "./components/Pages/ResetPassword";

import ActivityChecker from "./Admin/Components/ActivityChecker";
import ActivateForm from "./Admin/Pages/ActivateForm";
// import ErrorPage from "./components/Pages/ErrorPage";
// import ExecutiveSummary from "./Admin/Pages/ExecutiveSummary";
export const SelectedSegmentContext = React.createContext({});
const App = () => {
    const [menuToggle, setMenuToggle] = useState(true);
    const [drawerExpanded, setDrawerExpanded] = React.useState(false);
    let location = useLocation();
    const [selectedSegment, setSelectedSegment] = useState([]);
    if(location.pathname !== "/segmentation-dashboard") {
        sessionStorage.removeItem('searchSegmentName');
        sessionStorage.removeItem('dropDownStatus');
        sessionStorage.removeItem('dropDownUser');
        sessionStorage.removeItem('dropDownGroup');
    }

    const signOut = async ()   => {
        // e.preventDefault();
        try {
            await semcastAxios.get(SIGN_OUT_API, {withCredentials: true});
            const loggedInFromToolbox = sessionStorage.getItem("isToolboxLogged");
            removeDataFromStorage();

            // reset login attempts cookie
            document.cookie = "attempts=0";

            if (loggedInFromToolbox) {
                window.location.href = "/identitytoolbox";
            } else {
                window.location.href = "/"
            }

            // history.push("/");
        } catch (e) {
        }
    };

    useEffect(() => {
        if (location.pathname === '/' && sessionStorage.getItem("isToolboxLogged")) {
            document.documentElement.style.setProperty('--global-landing-page-color', '#1d3557');
            document.documentElement.style.setProperty('--global-footer-color', '#457B9D');
            signOut();
            return;
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname === '/identitytoolbox' || sessionStorage.getItem("isToolboxLogged")) {
            document.documentElement.style.setProperty('--global-landing-page-color', '#1d3557');
            document.documentElement.style.setProperty('--global-footer-color', '#457B9D');
            document.title = "Identity Toolbox";
        }
    }, []);

    return (
        <>
            <GoogleReCaptchaProvider
                useRecaptchaNet={false}
                reCaptchaKey={"6Ld9kioeAAAAAFJzdwbkSHh2oj4m6DngwRJ0KyGz"}
                scriptProps={{ async: true, defer: true, appendTo: 'body' }}
            >
                {/*{isUserLoggedIn() ? <Header menuToggle={menuToggle} setMenuToggle={setMenuToggle}*/}
                {/*                            drawerExpanded={drawerExpanded} setDrawerExpanded={setDrawerExpanded}/> :*/}
                {/*    <HeaderIfNoSession /> }{" "}*/}
                {isUserLoggedIn() ? <Header menuToggle={menuToggle} setMenuToggle={setMenuToggle}
                                            drawerExpanded={drawerExpanded} setDrawerExpanded={setDrawerExpanded}/> : <div></div>}
                <Switch>
                    {/*<Route path={CONTACT_US_ROUTE}> <ContactUsPage /> </Route>*/}
                    {/*<Route path={"/contact"}> <ContactUsPage loggedIn={false}/> </Route>*/}
                    {/*<Route exact path="/" component={isUserLoggedIn() ? SegmentationDashboard : Home} />*/}
                    {/*Uncomment below to see tree dashboard*/}
                    {/*<Route exact path="/" render={()=>isUserLoggedIn() ? (*/}
                    {/*    <Redirect to="/segmentation-dashboard"/>*/}
                    {/*) : (*/}
                    {/*    <Home/>*/}
                    {/*)} />*/}
                    <Route exact path="/identitytoolbox" component={HomeToolbox} />
                    <Route exact path="/" component={Home} />
                    {/*{window.location.href !== TOOLBOX_URL ? (<Route exact path="/" component={Home} />) : ""}*/}
                    {/*<Route path="/forgot-password" component={ForgotPassword} />*/}
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    {/*<Route path="/login" component={LoginPage} />*/}
                    {/* {!loggedIn && <Route path={CONTACT_US_ROUTE} component={ContactUs} />} */}
                    {/*<PrivateRoute path="/admin" component={Dashboard} />*/}
                    {/*<Route path="/password-reset/:id/:t" component={ResetPassword} />*/}
                    <Route path="/password-reset" component={ResetPassword} />

                    <div
                        id="main-wrapper"
                        className={"show " + (menuToggle ? "menu-toggle" : " ")}
                    >
                        {/*{isUserLoggedIn()}*/}
                        {/*<PrivateRoute*/}
                        {/*  path="/business-dashboard"*/}
                        {/*  component={BusinessDashboard}*/}
                        {/*/>*/}

                        {/*<Route render={() => isUserLoggedIn() ? <Redirect to="/segmentation-dashboard" /> : <Redirect to="/" />} />*/}
                        <SelectedSegmentContext.Provider
                            value={{
                                selectedSegment,
                                setSelectedSegment
                            }}
                        >
                            <SideNavigationBar drawerExpanded={drawerExpanded} setDrawerExpanded={setDrawerExpanded}>
                                {/*<PrivateRoute*/}
                                {/*    path="/segmentation-dashboard"*/}
                                {/*    component={SegmentationDashboard}*/}
                                {/*/>*/}
                                {/*Uncomment below to see tree dashboard*/}
                                <PrivateRoute
                                    path="/segmentation-dashboard"
                                    component={TreeSegmentationDashboard}
                                />
                                <PrivateRoute path={CONTACT_US_ROUTE}> <ContactUsPage loggedIn={true}/> </PrivateRoute>
                                <PrivateRoute path={HELP_CENTER_ROUTE}> <HelpCenterPage loggedIn={true}/> </PrivateRoute>
                                <PrivateRoute path="/onboarding" component={OnBoarding} />
                                <PrivateRoute path="/audience-design" component={AudienceDesign} />
                                {/*<PrivateRoute path="/audience-design-edit/:segmentId?/:segmentName?/:targetType?" component={AudienceDesignEdit} />*/}
                                <PrivateRoute path="/audience-design-edit" component={AudienceDesignEdit} />
                                <PrivateRoute path="/data-enhancement" component={DataEnhancement} />
                                <PrivateRoute path="/activate-media" component={ActivateMedia} />
                                {/* {loggedIn && ( */}
                                {/*<Route path={CONTACT_US_ROUTE} component={ContactUs} />*/}
                                {/* )} */}
                                {/*<PrivateRoute path={PERMISSIONS_ROUTE} component={Permission} />*/}
                                {/*<PrivateRoute path={PERMISSIONS_ROUTE} component={PermissionPage} />*/}
                                {/*<PrivateRoute path="/attribution" component={Attribution} />*/}
                                {/*<Route path={USER_PROFILE_ROUTE} component={Profile} />*/}
                                {/*<Route path={USER_PROFILE_ROUTE} component={ProfilePage} />*/}
                                {/*<PrivateRoute path={ACCOUNT_DETAILS_ROUTE} component={AccountDetailsMediaMint} />*/}
                                <PrivateRoute path={"/admin-settings"} component={AdminTabsPage} />
                                <PrivateRoute
                                    path="/business-dashboard"
                                    component={BusinessDashboard}
                                />
                                <PrivateRoute
                                    path="/dashboard"
                                    component={Dashboard}
                                />
                                {/*<PrivateRoute*/}
                                {/*    path="/executive-summary"*/}
                                {/*    component={ExecutiveSummary}*/}
                                {/*/>*/}
                                <Route path={ACTIVATE_SEGMENT_ROUTE} component={ActivateSegment} />
                                <PrivateRoute path={ACTIVATE_FORM_ROUTE} component={ActivateForm} />
                            </SideNavigationBar>
                        </SelectedSegmentContext.Provider>
                        {/*<Route path={ACTIVATE_SEGMENT_ROUTE} component={ActivateSegment} />*/}
                        {/*<PrivateRoute path="/accounts-details" component={AccountsDetailsSetup} />*/}
                        <PrivateRoute path="/create-account" component={CreateAccount} />
                        <PrivateRoute path="/account-pricing" component={AccountPricing} />
                        <PrivateRoute path="/user-search" component={UserSearch} />
                        <PrivateRoute path={APP_STATUS_ROUTE} component={AppStatus} />
                        <PrivateRoute path={JOB_STATUSES_ROUTE} component={JobStatus} />
                        <PrivateRoute path="/billing" component={Billing} />

                        {/*<Route render={() => isUserLoggedIn() ? <Redirect to="/segmentation-dashboard" /> : <Redirect to="/" />} />*/}
                    </div>
                    {/*<Route render={() => isUserLoggedIn() ? <Redirect to="/segmentation-dashboard" /> : <Redirect to="/" />} />*/}
                    {/*<PrivateRoute path="/*" component={TreeSegmentationDashboard}/>*/}
                </Switch>
            </GoogleReCaptchaProvider>

            <ActivityChecker />
        </>
    );
};

export default App;
