import React, {useEffect, useRef, useState} from "react";
import {Bar, Line, Pie, Radar} from "test-react-chartjs-2";
import {Chart as ChartJS} from "chart.js";
import {Sortable} from "@progress/kendo-react-sortable";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import "./Dashboard.css";
import * as chartInfo from "./ChartData";
import {PDFExport, PDFMargin} from "@progress/kendo-react-pdf";
import {useHistory, useLocation} from "react-router-dom";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {Slider, SliderLabel, RadioGroup} from '@progress/kendo-react-inputs';
import {
    Map,
    MapBubbleLayer,
    MapBubbleLayerTooltip,
    MapLayers,
    MapMarkerLayer,
    MapTileLayer
} from "@progress/kendo-react-map";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {
    BUBBLE_MAP, DOWNLOAD_DELIMITED_DATA, DOWNLOAD_PROFILE,
    MARKER_MAP,
    SEGMENT_ID_PLACE_HOLDER, SEGMENT_REPORT_REGENERATE_API,
    SEGMENTS_REPORT_DETAIL,
    SUCCESS_STATUS
} from "../../api/constants";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";
import Loader from "../Components/Loader";
import {getFTPSettings} from "../../actions/OnBoardingActions";

export default function Dashboard() {
    const [margin, setMargin] = useState('');
    const [toggleDialogOpen, setToggleDialogOpen] = useState(false);
    const [currentConnection, setCurrentConnection] = useState({text: '', id: 0});
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [currentFormat, setCurrentFormat] = useState({text:"CSV", id: 0});
    const [isLoaded, setIsLoaded] = useState(false);

    const PageTemplate = (props) => {
        return (
            <div className="page-template consumer-report">
                <div className="header">
                        <div className="report-logo" style={{marginLeft:margin}}>
                            {selectedFile == null && historyLogoImage == null &&(
                                    <img
                                        src="/images/legacy_audience_images/logo-black.png"
                                        width="auto"
                                        height="40"
                                        alt=""
                                    />
                            )}
                            {selectedFile != null && historyLogoImage == null &&(
                                    <img src={selectedFile} width="auto" height="40" alt=""/>
                            )}
                            {selectedFile == null && historyLogoImage != null &&(
                                <img src={historyLogoImage} width="auto" height="40" alt=""/>
                            )}
                        </div>
                        <div className="report-information aside">
                            <div className="report-title">{reportTitle}</div>
                            <div className="report-date">
                                {startDate.toLocaleString("en-us", {month: "long"})}{" "}
                                {startDate.getDate()}, {startDate.getFullYear()}
                            </div>
                        </div>
                </div>
                <div className="footer">
                    {/*<div className="row d-flex inner-footer align-items-center">*/}
                        {/*<div className="col-4 logo align-items-center">*/}
                            {/*<div className="logo">*/}
                            {/*    {selectedFile == null && (*/}
                            {/*        <div className="report-logo-default aside">*/}
                            {/*            <img*/}
                            {/*                src="/images/legacy_audience_images/logo-black.png"*/}
                            {/*                width="240"*/}
                            {/*                height="30"*/}
                            {/*                alt=""*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*    {selectedFile != null && (*/}
                            {/*        <div className="report-logo aside left">*/}
                            {/*            <img src={selectedFile} width="auto" height="30" alt=""/>*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-5 text-nowrap">*/}
                            <div className="copyright">Copyright(c) Semcasting, Inc.</div>
                            <div className="page-number-wrapper">
                                Page {props.pageNum} of {props.totalPages}
                            </div>
                        {/*</div>*/}
                        {/*<div className="col-3 justify-content-right align-items-center">*/}
                            {/*{reportFooter !== null && reportFooter !== "" ? (*/}
                            {/*    <div className="footer-container right">{reportFooter} </div>*/}
                            {/*) : (*/}
                            {/*    <div className="footer-container right">*/}
                            {/*        Page {props.pageNum} of {props.totalPages}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*    <div className="footer-container right">*/}
                            {/*        Page {props.pageNum} of {props.totalPages}*/}
                            {/*    </div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    };


    const history = useHistory();

    const location = useLocation();
    const pdfExportComponent = useRef(null);
    const containerRef = useRef(null);
    const [chartRelatedData, setChartRelatedData] = useState([]);
    const [reportTitle, setReportTitle] = useState(null);
    const [reportFooter, setReportFooter] = useState(null);
    const [reportLogo, setReportLogo] = useState(null);
    const [categories, setCategories] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState(null);
    const [defaultFile, setDefaultFile] = useState(
        "/images/legacy_audience_images/logo-black.png"
    );
    const [reportNotes, setReportNotes] = useState(null);

    const [parentNode, setParentNode] = useState(null);
    const [selectAll, setSelectAll] = useState("checked");
    const [convertedMarkerLocations, setConvertedMarkerLocations] = useState([]);
    const [convertedBubbleLocations, setConvertedBubbleLocations] = useState([]);

    const segment_id = location.state.segment_id;
    const profile_data_obsolete = location.state.profile_data_obsolete;
    // console.log(profile_data_obsolete);

    let order;
    let groups = [];
    // let selectedGroups = useState([]);
    let sections = [];
    let notestring = [];
    const [sortable, setSortable] = useState(null);
    let result;

    if (location.state) {
        let chartJsonData2 = JSON.parse(location.state.data);
        console.log(chartJsonData2)
        //Remove Map chart until loading issues is resolved
        chartJsonData2["report-detail"] = chartJsonData2["report-detail"].filter(obj => {
            return obj.reportTitle !== "Map";
        })

        if (location.state.notes) {
            notestring.push(location.state.notes);
        } else {
            notestring.push("");
        }

        chartJsonData2["report-detail"].push({
            reportTitle: "Notes",
            rows: notestring,
        });

        chartJsonData2["report-detail"]
            .map((e, i) => {
                if (sections.indexOf(e["reportTitle"]) < 0) {
                    sections.push(e["reportTitle"]);
                }
                return i % 3 === 0
                    ? chartJsonData2["report-detail"].slice(i, i + 3)
                    : null;
            })
            .filter((e) => {
                return e;
            });

        let numOfCharts = [...new Set(chartJsonData2["report-detail"].map(item => item.reportTitle))];
        //remove Nationally from list
        sections = sections.filter((item) => item !== 'People Nationally')
        sections = sections.filter((item) => item !== 'Ethnicity')
        groups = sections
            .map((e, i) => {
                return i % numOfCharts.length === 0 ? sections.slice(i, i + numOfCharts.length) : null;
            })
            .filter((e) => {
                return e;
            });

        //Assign each table id number
        result = sections.map((title) => ({title}));
        for (var i = 0; i < sections.length; i++) {
            result[i].id = i + 1;
        }
    }
    //Get the Logo Image List From LocalStorage
    let logoHistoryList = JSON.parse(localStorage.getItem("imgData")) === null ? []:JSON.parse(localStorage.getItem("imgData"));
    //Transform image to base64 format to be stored in localStorage
    function getBase64Image(img) {
        let canvas = document.createElement("canvas");
        const newLogoimage = new Image(180,30);
        newLogoimage.src = img;
        canvas.width = img.width;
        canvas.height = img.height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0,0, newLogoimage.width, newLogoimage.height);

        let dataURL = canvas.toDataURL();

        return dataURL.replace(/^data:image\/(png|jpg|jpeg|svg);base64,/, "");
    }

    const [logoFileName, setLogoFileName] = useState(null);
    const onFileChange = (event) => {
        try {
            setHistoryLogoImage(null);
            setLogoFileName(event.target.files[0].name);
            const fileType = event.target.files[0].name.split(".")[1];
            if (
                fileType === "png" ||
                fileType === "jpeg" ||
                fileType === "jpg" ||
                fileType === "svg"
            ) {
                const totalBytes = event.target.files[0].size;
                const sizeInMB = totalBytes / 1000000;
                if (sizeInMB < 5) {
                    setSelectedFile(URL.createObjectURL(event.target.files[0]));
                    document.getElementById("defaultlogo").checked = false;
                } else {
                    event.target.value = null;
                    alert("File size should be less than 5MB");
                }
            } else {
                event.target.value = null;
                alert("File type should be in png, jpeg, jpg, svg formats.");
            }
        } catch (e) {
            // console.log(e);
        }
    };
    const handleLogoCheckbox = (e) => {
        // e.preventDefault();
        if (e.target.checked === true) {
            setDefaultFile(defaultFile);
            setSelectedFile(null);
            document.getElementById("uploadedlogo").value = "";
            setHistoryLogoImage(null);
        } else {
            return;
        }
    };

    // const container = document.getElementById("container");
    // console.log(container);
    const handleSelectAllFn = (e) => {


        if (e.target.checked === true) {

            for (let i = 0; i < sections.length; i++) {
                chartRelatedData[sections[i]]["active"] = true
            }
            ;

            for (let j = 0; j < groups.length; j++) {
                for (let k = 0; k < groups[j].length; k++) {
                    document.getElementById("select" + j + k).checked = true;
                }
            }
        } else {
            for (let i = 0; i < sections.length; i++) {
                chartRelatedData[sections[i]]["active"] = false
            }
            for (let j = 0; j < groups.length; j++) {
                for (let k = 0; k < groups[j].length; k++) {
                    document.getElementById("select" + j + k).checked = false;
                }
            }
        }
        let memo1 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo1.includes(obj.title)));
    };

    const handleSelectFn = (e) => {
        if (e.target.checked === false) {
            // console.log("deselected: ", e.target.value);
            chartRelatedData[e.target.value]["active"] = false;
            const index = sections.indexOf(e.target.value);

            if (index !== -1) {
                sections.splice(index, 1);
            }

            document.getElementById("selectAll").checked = false;
            document.getElementById("selectAll").indeterminate = true;

            let lengthUnchecked = Object.keys(sections.filter((e) => chartRelatedData[e]["active"] === false)).length + 1;


            if (sortable) {
                document.getElementById("selectAll").checked = false;
                document.getElementById("selectAll").indeterminate = true;
            } else if (lengthUnchecked === Object.keys(chartRelatedData).length) {

                document.getElementById("selectAll").checked = false;

            }

        } else if (e.target.checked === true) {
            document.getElementById("selectAll").indeterminate = true;
            chartRelatedData[e.target.value]["active"] = true;
            sections.push(e.target.value);

            if (sortable.length === Object.keys(chartRelatedData).length - 1) {
                document.getElementById("selectAll").checked = true;
                document.getElementById("selectAll").indeterminate = false;
            }
        }


        const memo2 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo2.includes(obj.title)));

        if (Object.keys(sections.filter((e) => chartRelatedData[e]["active"] === true)).length === 0) {
            document.getElementById("selectAll").checked = false;
            document.getElementById("selectAll").indeterminate = false;
        }

    };

    const handleResetFn = () => {
        const memo3 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo3.includes(obj.title)));

    }
    //Find and return chart id to manage chart order
    const idFinder = (chartTitle) => {
        const chart = sortable.find((chart) => chart.title === chartTitle);
        return chart ? chart.id : null;
    }

    const logoHistDefaultItem = {text: "Choose From History ...", file_base64:null};
    const [historyLogoImage, setHistoryLogoImage] = useState(null);
    useEffect(()=>{

    },[historyLogoImage, logoFileName])

    //Handle Logo dropdown in Edit dialogue
    const onImgHistListChange = (e) => {
        try{
            // console.log(e.target.value)
            document.getElementById("defaultlogo").checked = false;
            document.getElementById("uploadedlogo").value="";
            setSelectedFile(null);
            if(e.target.value.text === "Choose From History ..."){
                setHistoryLogoImage( e.target.value.file_base64);
            }else{
                setHistoryLogoImage("data:image/png/svg/jpg;base64," + e.target.value.file_base64);
            }

        }catch (e) {
            // console.log(e)
        }
    }

    const saveChanges = () => {
        try {
            setCategories(
                sections.filter((e) => chartRelatedData[e]["active"] === true)
            );
            order = [sortable.map((e) => e.id)];
            for (let i = 0; i < order[0].length; i++) {
                containerRef.current.appendChild(document.getElementById("div" + order[0][i]));
            }
            let bannerImage = document.getElementById('uploaded-logo-img');
            let imgData = getBase64Image(bannerImage);
            let imageObj = {
                text: "" + logoFileName,
                file_base64: imgData
            };
            logoHistoryList.unshift(imageObj)
            localStorage.setItem("imgData", JSON.stringify(logoHistoryList.slice(0,5)));
        } catch (e) {
            // console.log(e);
        }
    };

    function handleSubmit(event) {
        event.preventDefault();
    }
    function onZoomStart(event){
        event.preventDefault();
    }

    const cancelChanges = () => {
        window.location.reload();
        // console.log("event canceled");
    };
    /************************ Map controls*******************/
    const bubbleStyle = {
        fill: {
            color: "red",
            opacity: 0.5
        },
        stroke: {
            width: 1,
            color: "black"
        }
    };
    const renderBubbleTooltip = (props) => (
        <span>
                    {props.dataItem.zip5} : {props.value}
    </span>
    );

    const mapRadioData = [
        { label: "Marker", value: "marker" },
        { label: "Bubble", value: "bubble" }
    ];
    const [mapRadioChecked, setMapRadioChecked] = React.useState(mapRadioData[0].value);
    const [markerLayer, setMarkerLayer] = React.useState(1);
    const [bubbleLayer, setBubbleLayer] = React.useState(-1);
    const imageHistoryData = JSON.parse(localStorage.getItem('imgData'));
    useEffect(() => {
        setParentNode(containerRef.current);
        setCategories(sections);
        setSortable(result);

        if (location.state) {
            let chartJsonData = JSON.parse(location.state.data);
            chartJsonData["report-detail"].push({
                reportTitle: "Notes",
                rows: notestring,
            });
            chartJsonData = chartJsonData["report-detail"];

            let chartData = {};

            /********************************* Activate Segment Summary *********************************/
            let activateSegmentSummaryData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Activate Segment Summary";
            });
            let labels = [];
            let values = [];
            let rows = [];

            const headers = ["Value", "Match ", "People", "+/- Avg", "Rank", "Sites", "People", "Places", "Platform", "Organizations", "Categories", "Category", "Title"];

            if(activateSegmentSummaryData){
                const removeObjectsWithValueZero = (obj) => {
                    for (const [key, value] of Object.entries(obj)) {
                        if (value === 0) {
                            delete obj[key];
                        }
                    }
                    return obj;
                };


                chartData["Activate Segment Summary"] = [];

                chartData["Activate Segment Summary"][0] = {
                    rows: removeObjectsWithValueZero(activateSegmentSummaryData[0].rows[0]),
                };
                chartData["Activate Segment Summary"]["active"] = true;

                chartData["Activate Segment Summary"][1] = {
                    rows: removeObjectsWithValueZero(activateSegmentSummaryData[1].rows[0]),
                };
            }


            /********************************* Top Zip5s Data *********************************/

            let topZip5sData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "People Top Zip5s";
            })[0];

            labels = [];
            values = [];
            let values2 = [];

            if(topZip5sData){
                /*** Remove Extra Header From Json File If Exist ***/

                const testTopZip5sData = headers.some((e) =>
                    Object.values(topZip5sData.rows[0]).includes(e)
                );

                if (testTopZip5sData) {
                    rows = topZip5sData.rows.slice(1);
                } else {
                    rows = topZip5sData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["zip5"]);
                    values.push(item["audience_people"]);
                    values2.push(item["zip5_people"]);
                });

                chartData["People Top Zip5s"] = {headers: topZip5sData.header, rows: rows, active: true};
                chartInfo.topZip5sData.labels = labels;

                chartInfo.topZip5sData.labels = labels;
                // chartInfo.topZip5sData.datasets[0].data = Object.values(nationalAvgTopZip5s);
                chartInfo.topZip5sData.datasets[1].data = values;
                chartInfo.topZip5sData.datasets[0].data = values2;
                chartInfo.topZip5sData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.topZip5sData.datasets[0].borderColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.topZip5sData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Top States Data *********************************/

            let topStatesData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "People Top States";
            })[0];

            labels = [];
            values = [];
            values2 = [];

            /*** Remove Extra Header From Json File If Exist ***/
            if(topStatesData){
                const testTopStatesData = headers.some((e) =>
                    Object.values(topStatesData.rows[0]).includes(e)
                );

                if (testTopStatesData) {
                    rows = topStatesData.rows.slice(1);
                } else {
                    rows = topStatesData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["state"]);
                    values.push(item["state_people"]);
                    values2.push(item["audience_people"]);
                });

                chartData["People Top States"] = {headers: topStatesData.header, rows: rows, active: true};
                chartInfo.topStatesData.labels = labels;


                // chartInfo.ageData.datasets[0].data = Object.values(nationalAvgAge);
                chartInfo.topStatesData.datasets[0].data = values;
                chartInfo.topStatesData.datasets[1].data = values2;
                chartInfo.topStatesData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
                chartInfo.topStatesData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
            }


            /********************************* Location Data *********************************/
            let locationData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Location";
            })[0];
            labels = [];
            values = [];
            if(locationData){
                /*** Remove Extra Header From Json File If Exist ***/
                const testLocation = headers.some((e) =>
                    Object.values(locationData.rows[0]).includes(e)
                );

                if (testLocation) {
                    rows = locationData.rows.slice(1);
                } else {
                    rows = locationData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Location"] = {
                    headers: locationData.header,
                    rows: rows,
                    active: true,
                };
                chartInfo.locationData.labels = labels;
                chartInfo.locationData.datasets[0].data = values;
                chartInfo.locationData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].pieColor;
            }

            /********************************* Digital Activity Data *********************************/
            let digitalActivityData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Digital Activity";
            })[0];
            labels = [];
            values = [];
            if(digitalActivityData){
                /*** Remove Extra Header From Json File If Exist ***/
                const testDigitalActivity = headers.some((e) =>
                    Object.values(digitalActivityData.rows[0]).includes(e)
                );

                if (testDigitalActivity) {
                    rows = digitalActivityData.rows.slice(1);
                } else {
                    rows = digitalActivityData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Digital Activity"] = {
                    headers: digitalActivityData.header,
                    rows: rows,
                    active: true,
                };
                chartInfo.digitalActivityData.labels = labels;
                chartInfo.digitalActivityData.datasets[0].data = values;
                chartInfo.digitalActivityData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].pieColor;
            }
            /********************************* Age Data *********************************/
            let ageData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Age";
            })[0];

            labels = [];
            values = [];
            values2 = [];

            /*** Remove Extra Header From Json File If Exist ***/
            if(ageData){
                const testAge = headers.some((e) =>
                    Object.values(ageData.rows[0]).includes(e)
                );

                if (testAge) {
                    rows = ageData.rows.slice(1);
                } else {
                    rows = ageData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });

                chartData["Age"] = {headers: ageData.header, rows: rows, active: true};
                chartInfo.ageData.labels = labels;

                /******* Calculate National Avg ******/
                let nationalAvgAge = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.ageData.datasets[0].data = Object.values(nationalAvgAge);
                chartInfo.ageData.datasets[1].data = values2;
                chartInfo.ageData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
                chartInfo.ageData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
            }


            /********************************* Social Matrix Data *********************************/
            let socialMatrixData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Social Matrix";
            });
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(socialMatrixData){
                const testSocialMatrix = headers.some(
                    (e) =>
                        Object.values(socialMatrixData[0].rows[0]).includes(e) ||
                        Object.values(socialMatrixData[1].rows[0]).includes(e)
                );

                if (testSocialMatrix) {
                    rows = socialMatrixData[0].rows
                        .slice(1)
                        .concat(socialMatrixData[1].rows.slice(1));
                } else {
                    rows = socialMatrixData[0].rows.concat(socialMatrixData[1].rows);
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Social Matrix"] = {
                    headers: socialMatrixData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgSocialMatrix = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.socialMatrixData.labels = labels;
                chartInfo.socialMatrixData.datasets[0].data = Object.values(nationalAvgSocialMatrix);
                chartInfo.socialMatrixData.datasets[1].data = values2;
                chartInfo.socialMatrixData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].transColor1;
                chartInfo.socialMatrixData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].transColor2;
            }


            /********************************* Ethnicity Data *********************************/
            //FD#13519
            // let ethnicityData = chartJsonData.filter((obj) => {
            //     return obj.reportTitle === "Ethnicity";
            // })[0];
            // labels = [];
            // values = [];
            //
            // /*** Remove Extra Header From Json File If Exist ***/
            // if(ethnicityData){
            //     const testEthnicity = headers.some((e) =>
            //         Object.values(ethnicityData.rows[0]).includes(e)
            //     );
            //
            //     if (testEthnicity) {
            //         rows = ethnicityData.rows.slice(1);
            //     } else {
            //         rows = ethnicityData.rows;
            //     }
            //
            //     rows.forEach((item, i) => {
            //         labels.push(item["Value"]);
            //         values.push(item["Match %"]);
            //     });
            //     chartData["Ethnicity"] = {
            //         headers: ethnicityData.header,
            //         rows: rows,
            //         active: true,
            //     };
            //     chartInfo.ethnicityData.labels = labels;
            //     chartInfo.ethnicityData.datasets[0].data = values;
            //     chartInfo.ethnicityData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            // }



            /********************************* Political Party Data *********************************/
            let politicalPartyData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Political Party";
            })[0];
            labels = [];
            values = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(politicalPartyData){
                const testPolitical = headers.some((e) =>
                    Object.values(politicalPartyData.rows[0]).includes(e)
                );

                if (testPolitical) {
                    rows = politicalPartyData.rows.slice(1);
                } else {
                    rows = politicalPartyData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Political Party"] = {
                    headers: politicalPartyData.header,
                    rows: rows,
                    active: true,
                };
                chartInfo.politicalPartyData.labels = labels;
                chartInfo.politicalPartyData.datasets[0].data = values;
                chartInfo.politicalPartyData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].pieColor;
            }


            /********************************* Income Range Data *********************************/
            let incomeRangeData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Income Range";
            })[0];
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(incomeRangeData){
                const testIncomeRange = headers.some((e) =>
                    Object.values(incomeRangeData.rows[0]).includes(e)
                );

                if (testIncomeRange) {
                    rows = incomeRangeData.rows.slice(1);
                } else {
                    rows = incomeRangeData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Income Range"] = {
                    headers: incomeRangeData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgIncRange = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.incomeRangeData.labels = labels;
                chartInfo.incomeRangeData.datasets[0].data = Object.values(nationalAvgIncRange);
                chartInfo.incomeRangeData.datasets[1].data = values2;
                chartInfo.incomeRangeData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.incomeRangeData.datasets[0].borderColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.incomeRangeData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }



            /********************************* Charitable Contributions Decile Data *********************************/
            let charitableContributionDecileData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Charitable Contributions Decile";
            })[0];
            console.log(charitableContributionDecileData)
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(charitableContributionDecileData){
                const testCharitable = headers.some((e) =>
                    Object.values(charitableContributionDecileData.rows[0]).includes(e)
                );

                if (testCharitable) {
                    rows = charitableContributionDecileData.rows.slice(1);
                } else {
                    rows = charitableContributionDecileData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Charitable Contributions Decile"] = {
                    headers: charitableContributionDecileData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgChariContriDecil = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.charitableContributionDecileData.labels = labels;
                chartInfo.charitableContributionDecileData.datasets[0].data = Object.values(nationalAvgChariContriDecil);
                chartInfo.charitableContributionDecileData.datasets[1].data = values2;
                chartInfo.charitableContributionDecileData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.charitableContributionDecileData.datasets[0].borderColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.charitableContributionDecileData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Home Value Data *********************************/

            let homeValueData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Home Value";
            })[0];
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(homeValueData){
                const testHome = headers.some((e) =>
                    Object.values(homeValueData.rows[0]).includes(e)
                );

                if (testHome) {
                    rows = homeValueData.rows.slice(1);
                } else {
                    rows = homeValueData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Home Value"] = {
                    headers: homeValueData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgHomeValue = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.homeValueData.labels = labels;
                chartInfo.homeValueData.datasets[0].data = Object.values(nationalAvgHomeValue);
                chartInfo.homeValueData.datasets[1].data = values2;
                chartInfo.homeValueData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.homeValueData.datasets[0].borderColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.homeValueData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Discretionary Income Decile Data *********************************/
            let discretionaryData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Discretionary Income Decile";
            })[0];
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(discretionaryData){
                const testDiscretionary = headers.some((e) =>
                    Object.values(discretionaryData.rows[0]).includes(e)
                );

                if (testDiscretionary) {
                    rows = discretionaryData.rows.slice(1);
                } else {
                    rows = discretionaryData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Discretionary Income Decile"] = {
                    headers: discretionaryData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgDiscreIncome = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.discretionaryData.labels = labels;
                chartInfo.discretionaryData.datasets[0].data = Object.values(nationalAvgDiscreIncome);
                chartInfo.discretionaryData.datasets[1].data = values2;
                chartInfo.discretionaryData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.discretionaryData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Net Worth Data *********************************/
            let netWorthData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Net Worth";
            })[0];
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(netWorthData){
                const testNetWorth = headers.some((e) =>
                    Object.values(netWorthData.rows[0]).includes(e)
                );

                if (testNetWorth) {
                    rows = netWorthData.rows.slice(1);
                } else {
                    rows = netWorthData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Net Worth"] = {
                    headers: netWorthData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgNetWorth = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.netWorthData.labels = labels;
                chartInfo.netWorthData.datasets[0].data = Object.values(nationalAvgNetWorth);
                chartInfo.netWorthData.datasets[1].data = values2;
                chartInfo.netWorthData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].transColor2;
                chartInfo.netWorthData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].transColor1;
            }


            /********************************* Invested Assets Decile data *********************************/
            let investedAssetsData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Invested Assets Decile";
            })[0];
            labels = [];
            values = [];
            values2 = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(investedAssetsData){
                const testAssets = headers.some((e) =>
                    Object.values(investedAssetsData.rows[0]).includes(e)
                );

                if (testAssets) {
                    rows = investedAssetsData.rows.slice(1);
                } else {
                    rows = investedAssetsData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["+/- Avg"]);
                    values2.push(item["Match %"]);
                });
                chartData["Invested Assets Decile"] = {
                    headers: investedAssetsData.header,
                    rows: rows,
                    active: true,
                };

                /******* Calculate National Avg ******/
                let nationalAvgInvestedAssests = Object.keys(values2).reduce((a, k) => {
                    a[k] = values2[k] - values[k];
                    return a;
                }, {});

                chartInfo.investedAssetsData.labels = labels;
                chartInfo.investedAssetsData.datasets[0].data = Object.values(nationalAvgInvestedAssests);
                chartInfo.investedAssetsData.datasets[1].data = values2;
                chartInfo.investedAssetsData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.investedAssetsData.datasets[0].borderColor = chartInfo.ConsumerThemeDictionary[0].barColor2;
                chartInfo.investedAssetsData.datasets[1].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Genders Present Start *********************************/
            let gendersPresent = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Genders Present";
            })[0];
            labels = [];
            values = [];

            /*** Remove Extra Header From Json File If Exist ***/
            if(gendersPresent){
                const testGender = headers.some((e) =>
                    Object.values(gendersPresent.rows[0]).includes(e)
                );

                if (testGender) {
                    rows = gendersPresent.rows.slice(1);
                } else {
                    rows = gendersPresent.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Genders Present"] = {
                    headers: gendersPresent.header,
                    rows: rows,
                    active: true,
                };
                chartInfo.gendersPresentData.labels = labels;
                chartInfo.gendersPresentData.datasets[0].data = values;
                chartInfo.gendersPresentData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].pieColor;
            }


            /********************************* Number of Children Data *********************************/
            let numberOfChildrenData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Number of Children";
            })[0];
            labels = [];
            values = [];
            /*** Remove Extra Header From Json File If Exist ***/
            if(numberOfChildrenData){
                const testChildren = headers.some((e) =>
                    Object.values(numberOfChildrenData.rows[0]).includes(e)
                );

                if (testChildren) {
                    rows = numberOfChildrenData.rows.slice(1);
                } else {
                    rows = numberOfChildrenData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Number of Children"] = {
                    headers: numberOfChildrenData.header,
                    rows: rows,
                    active: true,
                };
                chartInfo.numberOfChildrenData.labels = labels;
                chartInfo.numberOfChildrenData.datasets[0].data = values;
                chartInfo.numberOfChildrenData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].barColor1;
            }


            /********************************* Top Contextual Samples Data *********************************/
            let topContextualData;
            let topContextualCountsData;
            //Table Names Changed In Some Segments
            if(chartJsonData.find((obj) => obj.reportTitle === "Top Contextual Samples") !== undefined){
                topContextualData = chartJsonData.filter((obj) => {
                    return obj.reportTitle === "Top Contextual Samples";
                })[0];
            }else if(chartJsonData.find((obj) => obj.reportTitle === "Top Contextual Sample Counts") !== undefined){
                topContextualCountsData = chartJsonData.filter((obj) => {
                    return obj.reportTitle === "Top Contextual Sample Counts";
                })[0];
            }

            labels = [];
            values = [];
            rows = [];

            /*** Segment Includes Top Contextual Data/Not ***/
            if (topContextualData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopContextual = headers.some((e) =>
                    Object.values(topContextualData.rows[0]).includes(e)
                );

                if (testTopContextual) {
                    rows = topContextualData.rows.slice(1);
                } else {
                    rows = topContextualData.rows;
                }

                chartData["Top Contextual Samples"] = {
                    headers: topContextualData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual Samples data included");
            }
            if (topContextualCountsData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopContextualCounts = headers.some((e) =>
                    Object.values(topContextualCountsData.rows[0]).includes(e)
                );

                if (testTopContextualCounts) {
                    rows = topContextualCountsData.rows.slice(1);
                } else {
                    rows = topContextualCountsData.rows;
                }

                chartData["Top Contextual Sample Counts"] = {
                    headers: topContextualCountsData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual Sample Counts data included");
            }
            /********************************* Top 20 Consumer Titles Data *********************************/
            let topConsumerTitlesData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Top 20 Consumer Titles";
            })[0];
            labels = [];
            values = [];
            rows = [];

            /*** Segment Includes Top Contextual Data/Not ***/
            if (topConsumerTitlesData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopConsumerTitlesData = headers.some((e) =>
                    Object.values(topConsumerTitlesData.rows[0]).includes(e)
                );

                if (testTopConsumerTitlesData) {
                    rows = topConsumerTitlesData.rows.slice(1);
                } else {
                    rows = topConsumerTitlesData.rows;
                }

                chartData["Top 20 Consumer Titles"] = {
                    headers: topConsumerTitlesData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top 20 Consumer Titles data included");
            }

            /********************************* Audience Supply Path Optimization Data *********************************/
            let audienceSupplyPathOptData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Audience Supply Path Optimization";
            })[0];
            labels = [];
            values = [];


            /*** Segment Includes Audience Supply Path Optimization Data/Not ***/
            if (audienceSupplyPathOptData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testAudienceSupPathOpt = headers.some((e) =>
                    Object.values(audienceSupplyPathOptData.rows[0]).includes(e)
                );

                if (testAudienceSupPathOpt) {
                    rows = audienceSupplyPathOptData.rows.slice(1);
                } else {
                    rows = audienceSupplyPathOptData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Platform"]);
                    values.push(item["Match %"]);
                });
                chartData["Audience Supply Path Optimization"] = {
                    headers: audienceSupplyPathOptData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual data included");
            }
            chartInfo.audienceSupplyPathOptData.labels = labels;
            chartInfo.audienceSupplyPathOptData.datasets[0].data = values;
            chartInfo.audienceSupplyPathOptData.datasets[0].backgroundColor = chartInfo.ConsumerThemeDictionary[0].pieColor;


            /*********************************  Map Chart Info *********************************/

            let mapData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Map";
            })[0];
            //if profile has map data


            labels = [];
            values = [];
            rows = [];

            if(mapData && mapData.rows){
                if(mapData?.rows[0]?.markers.length > 0 || mapData?.rows[1]?.bubbles.length > 0){
                    setConvertedMarkerLocations(mapData?.rows[0]?.markers);
                    setConvertedBubbleLocations(mapData?.rows[1]?.bubbles);

                    chartData["Map"] = {
                        headers: mapData.header,
                        rows: rows,
                        active: true,
                    };
                }
            }



            /*********************************  Map Chart End *********************************/
            /********************************* Notes Data *********************************/
            let noteContent = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Notes";
            })[0];
            rows = [];

            /*** Segment Includes Note Data/Not ***/
            if (noteContent) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testNotes = headers.some((e) =>
                    Object.values(noteContent.rows[0]).includes(e)
                );

                if (testNotes) {
                    rows = noteContent.rows.slice(1);
                } else {
                    rows = noteContent.rows;
                }
                chartData["Notes"] = {
                    headers: noteContent.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Notes Were Included");
            }

            try {
                if (location.state.notes) {
                    setReportNotes(location.state.notes);
                } else {
                    setReportNotes("");
                }
            } catch (e) {
                setReportNotes(e);
            }

            ////////////////////////////////////////////////////////////////////////////////
            setChartRelatedData(chartData);
            setReportTitle(location.state.segment_name);
        }
    }, [
        // mapRadioChecked
    ]);

    useEffect(()=>{
        if (mapRadioChecked === "marker") {
            // console.log("marker here")
            setMarkerLayer(1);
            setBubbleLayer(-1);
        } else if (mapRadioChecked === "bubble") {
            // console.log("bubble here")
            setMarkerLayer(-1);
            setBubbleLayer(1);
        }
    },[mapRadioChecked])
    /*******************************Sortable Item UI Start**********************************/

    const SortableItemUI = (props) => {
        const {isDisabled, isActive, style, attributes, dataItem, forwardRef} =
            props;
        const classNames = ["col-xs-6 col-sm-3"];
        if (isDisabled) {
            classNames.push("k-disabled");
        }
        return (
            <div
                ref={forwardRef}
                {...attributes}
                style={{
                    ...getBaseItemStyle(isActive),
                    ...style,
                }}
                className={classNames.join(" ")}
            >
                {dataItem.title}
            </div>
        );
    };

    const getBaseItemStyle = (isActive) => ({
        height: 30,
        lineHeight: "30px",
        fontSize: "16px",
        textAlign: "center",
        outline: "none",
        border: "1px solid",
        cursor: "move",
        display: "block",
        width: 300,
        background: isActive ? "#fff" : "var(--global-landing-page-color)" ,
        color: isActive ? "var(--global-landing-page-color)" : "white",
        borderColor: isActive ? "var(--global-landing-page-color)" : "white",
    });

    const onDragOver = (event) => {
        setSortable(event.newState);
    };

    /*******************************Sortable Item UI End**********************************/
    /*******************************Color Palette UI Start**********************************/
    const topZip5sRef = React.createRef();
    const topStatesRef = React.createRef();
    const locationPieRef = React.createRef();
    const digitalActivityPieRef = React.createRef();
    const ageBarRef = React.createRef();
    const socialMatrixRef = React.createRef();
    const ethnicityBarRef = React.createRef();
    const politicalPieRef = React.createRef();
    const incomeRangeRef = React.createRef();
    const charContributionsRef = React.createRef();
    const homeValRef = React.createRef();
    const disIncomeRef = React.createRef();
    const netWorthRef = React.createRef();
    const investedAssetsRef = React.createRef();
    const genderPieRef = React.createRef();
    const childrenBarRef = React.createRef();
    const audiencePieRef = React.createRef();
    const handleAllChartThemeChange = (event) => {
        try{

            topZip5sRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            topZip5sRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor2;
            topZip5sRef.current.update();

            topStatesRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            topStatesRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor2;
            topStatesRef.current.update();

            locationPieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            locationPieRef.current.update();

            digitalActivityPieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            digitalActivityPieRef.current.update();

            ageBarRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            ageBarRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor2;
            ageBarRef.current.update();

            socialMatrixRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.transColor1;
            socialMatrixRef.current.update();

            socialMatrixRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.transColor2;
            socialMatrixRef.current.update();

            ethnicityBarRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            ethnicityBarRef.current.update();

            politicalPieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            politicalPieRef.current.update();

            incomeRangeRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            incomeRangeRef.current.config._config.data.datasets[0].borderColor = event.target.value.barColor2;
            incomeRangeRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor1;
            incomeRangeRef.current.update();

            charContributionsRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            charContributionsRef.current.config._config.data.datasets[0].borderColor = event.target.value.barColor2;
            charContributionsRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor1;
            charContributionsRef.current.update();

            homeValRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            homeValRef.current.config._config.data.datasets[0].borderColor = event.target.value.barColor2;
            homeValRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor1;
            homeValRef.current.update();

            disIncomeRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            disIncomeRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor1;
            disIncomeRef.current.update();

            netWorthRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.transColor2;
            netWorthRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.transColor1;
            netWorthRef.current.update();

            investedAssetsRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            investedAssetsRef.current.config._config.data.datasets[0].borderColor = event.target.value.barColor2;
            investedAssetsRef.current.config._config.data.datasets[1].backgroundColor = event.target.value.barColor1;
            investedAssetsRef.current.update();

            genderPieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            genderPieRef.current.update();

            childrenBarRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            childrenBarRef.current.update();

            audiencePieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            audiencePieRef.current.update();

        }catch (e) {
            // console.log("handleAllChartThemeChange: " + e)
        }
    };
    /*******************************Color Palette UI End**********************************/
    /******************************* PDF Font Size **********************************/
    const initialStyles = `
        .k-pdf-export h4{
          font-size: 26px !important;
        }
        .k-pdf-export .row.pdf-data.consumer-reports-data-content .table thead{
          background-color: #ffffff;
          font-size: 20px !important;
        }
        .k-pdf-export td, 
        .k-pdf-export strong, 
        .k-pdf-export th,
        .k-pdf-export p {
          font-size: 20px !important;
          font-family:Helvetica, Arial, sans-serif !important;
        }
          `;
    const [styles, setStyles] = React.useState(initialStyles);

    const changeStyle = (e) => {
        let currStyle = '';

        switch (e.value) {
            case 7:
                currStyle = `.k-pdf-export h4{ 
                                font-size: 24px !important;
                              } 
                             .k-pdf-export .row.pdf-data.consumer-reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 18px !important;
                            }
                            .k-pdf-export td, 
                            .k-pdf-export strong, 
                            .k-pdf-export th,
                            .k-pdf-export p {
                              font-size: 18px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            case 14:
                currStyle = `.k-pdf-export h4{ font-size: 26px !important;}
                             .k-pdf-export .row.pdf-data.consumer-reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 20px !important;
                            }
                            .k-pdf-export td, 
                            .k-pdf-export strong, 
                            .k-pdf-export th,
                            .k-pdf-export p {
                              font-size: 20px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            case 21:
                currStyle = `.k-pdf-export h4{ font-size: 28px !important;} 
                             .k-pdf-export .row.pdf-data.consumer-reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 22px !important;
                            }
                            .k-pdf-export td, 
                            .k-pdf-export strong, 
                            .k-pdf-export th,
                            .k-pdf-export p {
                              font-size: 22px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            default:
        }
        setStyles(currStyle);
    };

    /******************************* PDF Font Size End **********************************/
    /******************************* Map **********************************/

    const center = [42.68, -71.11];
    let centerOfMap;

    // if(convertedMarkerLocations[0]){
    //     centerOfMap = convertedMarkerLocations[0].location;
    // console.log(centerOfMap)
    // }else{
    centerOfMap = [37.0902, -95.7129];
    // }
    const tileSubdomains = ["a", "b", "c"];

    // if(convertedMarkerLocations){
    //     centerOfMap = convertedMarkerLocations[0]?.location;
    //     // console.log(centerOfMap)
    // }else{
    //     centerOfMap = [37.0902, -95.7129];
    // }

    const tileUrl = (e) =>
        `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
    const attribution =
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';

    const handleMapRadioChange = (e) => {
        setMapRadioChecked(e.value);
    };



    /******************************* Map End **********************************/

    const backToDashboard = (e) => {
        history.push("/segmentation-dashboard");
    }

    // const exportPDFWithComponent = async () => {
    //     if (pdfExportComponent.current) {
    //         pdfExportComponent.current.save();
    //     }
    // };
    const handleRegenerateReport = async (segmentId) => {
        try {
            // setDisabled(true);
            let response = await semcastAxios.post(SEGMENT_REPORT_REGENERATE_API + segmentId + '/regenerate' , {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Regenerating Report. Please try again after regenerate process completes.", 30);
                // setDisabled(false);
                return response.data;
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else{
                showErrorToastMessage(error + " Please contact support.", 15);
            }
            // setDisabled(false);
        }
    }
    const pdfFileDownload = async (segmentId, profileDataObsolete) => {
        if (profileDataObsolete){
            await handleRegenerateReport(segmentId);
        }else{
            try{
                let response = await semcastAxios.get(DOWNLOAD_PROFILE.replace(SEGMENT_ID_PLACE_HOLDER, segment_id), {withCredentials: true, responseType: 'blob'}) //Specify blob file type
                if (response.status === 200) {
                    showSuccessToastMessage("Downloading", 1);
                    const url = window.URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = reportTitle + "_" + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + "_" + Math.floor(Date.now() / 1000) + ".pdf" //Give File Name
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            } catch (error) {
                // console.log(error.response)
                if(error.response.status === 404){
                    showErrorToastMessage("404: Missing mandatory JSON object: The file is out of date, please try again after your profile report regenerate completes.", 10);
                }else if (error.response.status === 500){
                    showErrorToastMessage("500: Internal error, please try again later", 10);
                }
            }
        }
    };
//get FTP connections for download data
    const getSettings = async() => {
        try {
            const settings = await getFTPSettings();
            if (settings && settings.data.length) {
                setAllSavedSettings(settings.data);
                const setting = settings.data[settings.data.length - 1];
                if (setting) {
                    setCurrentConnection({
                        ...currentConnection,
                        ['text']: setting.connection_name,
                        ['id']: setting.id,
                        // ['protocol']: setting.transfer_protocol
                    });
                } else {
                    setCurrentConnection({text: '', id: 0});
                }
            }
        } catch {
            showErrorToastMessage("Could not access Connection information.");
        } finally {
            setIsLoaded(true);
        }
    }
    useEffect(() => {
        getSettings();
    }, []);
    const jsonFileDownload = () => {
        const json_data = location.state.data;
        const fileName = reportTitle + "_" + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + "_" + Math.floor(Date.now() / 1000) + ".json";
        const data = new Blob([(json_data)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
    };
//Download data dialogue
    const toggleFileFormatDownloadDialogue = () => {
        setToggleDialogOpen(true);
    };

    const sendDataFile = async (segmentId, profileDataObsolete) => {
        const userId = JSON.parse(sessionStorage.getItem('user')).id;
        let getDataPayload = {
            user_id: userId,
            segment_id: segmentId,
            connection_id: currentConnection.id,
            data_format: currentFormat.text,
        }
        console.log(getDataPayload);
        try{
            let response = await semcastAxios.get(DOWNLOAD_DELIMITED_DATA.replace(SEGMENT_ID_PLACE_HOLDER, segmentId),{params: getDataPayload, withCredentials: true})
            if (response.status === 200) {
                showSuccessToastMessage("Sending File...", 5);
            }
        } catch (error) {
            // console.log(error.response)
            if(error.response.status === 404){
                showErrorToastMessage("404: Missing mandatory JSON object: The file is out of date, please try again after your profile report regenerate completes.", 10);
            }else if (error.response.status === 500){
                showErrorToastMessage("500: Internal error, please try again later", 10);
            }
        } finally {
            setToggleDialogOpen(false);
        }
    }
    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    const handleDataFormatSelectionChange = (e) => {
        console.log(e)
        setCurrentFormat(e.value);
    }
    const handleConnectionChange = (e) => {
        console.log(e.value);
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id,
            // ['protocol']: e.value.protocol
        });
    };
    const dataFormats = [
        {
            text: "CSV",
            id: 1,
        },
        {
            text: "Tab",
            id: 2,
        },
        {
            text: "Pipe",
            id: 3,
        }];

    /********************************* React ChartJS2 customization ***************/
    /********************************* More customization in ChartData.js file *****************/

    ChartJS.defaults.font.size = 17;
    ChartJS.defaults.font.family = "Helvetica";
    ChartJS.defaults.font.color = "#000000 !important";

    //For Sorting Activate Segment Summary to Match ADS
    const sortingArr1 = ["Total Records Uploaded", "Total Duplicate Records Uploaded", "Total Households","Total People", "Total Household Devices","Total Top Household Devices","Total Businesses", "Total Business Devices", "Total Top Business Devices"];
    const sortingArr2 = ["Total Household Network IP's", "Total Household WIFI IP's", "Total Phones", "Total Emails", "Total Business Network IP's", "Total Business WIFI IP's"];


    const MapComponent = () => {

        return (
            // <Map center={center} zoom={2}>
            //     <MapLayers>
            //         <MapTileLayer
            //             urlTemplate={tileUrl}
            //             subdomains={tileSubdomains}
            //             attribution={attribution}
            //             // zIndex={0}
            //         />
            //         <MapMarkerLayer
            //             data={convertedMarkerLocations}
            //             locationField="location"
            //             titleField=" "
            //             // zIndex={1}
            //         />
            //     </MapLayers>
            // </Map>
            <>
                <div className="map-style-radio-btn">
                    {/*<span>Change Map Styles:</span>*/}
                    <RadioGroup data={mapRadioData}
                                value={mapRadioChecked}
                                style={{alignSelf: "flex-start"}}
                                onChange={handleMapRadioChange}
                                layout="horizontal"/>
                </div>
                <Map center={centerOfMap} zoom={4}>
                    <MapLayers>
                        <MapTileLayer
                            urlTemplate={tileUrl}
                            subdomains={tileSubdomains}
                            attribution={attribution}
                            // zIndex={0}
                        />
                        {markerLayer === 1 ?
                            <MapMarkerLayer
                                data={convertedMarkerLocations}
                                locationField="location"
                                titleField=""
                                zIndex={markerLayer}
                            /> :
                            <MapBubbleLayer
                                data={convertedBubbleLocations}
                                locationField="location"
                                valueField="cnt"
                                attribution=""
                                style={bubbleStyle}
                                zIndex={bubbleLayer}
                            >
                                <MapBubbleLayerTooltip
                                    render={renderBubbleTooltip}/>
                            </MapBubbleLayer>
                        }
                    </MapLayers>
                </Map>
            </>
        )
    }


    return (
        <>
            {toggleDialogOpen && (
                <Dialog title={"Download Data - " + location.state.segment_name}
                        width={450}
                        closeIcon={false}
                        className={"dialog-standard download-data"}>
                    <div>
                        <Label className={"data-format-dropdown required-asterisk"}>Delimited Format</Label>
                    </div>
                    <div className={"pb-3"}>
                        <DropDownList
                            data={dataFormats}
                            textField="text"
                            dataItemKey="id"
                            onChange={(e) => handleDataFormatSelectionChange(e)}
                            value={currentFormat}
                            fillMode={"outline"}
                            className="connection-dialogue"
                            disabled={allSavedSettings.length === 0}
                            style={{fontSize: "14px"}}
                        />
                    </div>
                    <div>
                        <Label className={"connection-label required-asterisk"}>Direct Deliver To
                            Connection</Label>
                    </div>
                    <div>
                        {isLoaded ? (
                            <DropDownList
                                data={connections}
                                // itemRender={itemRender}
                                textField="text"
                                dataItemKey="id"
                                onChange={(e) => handleConnectionChange(e)}
                                value={currentConnection}
                                fillMode={"outline"}
                                className="connection-dialogue"
                                disabled={allSavedSettings.length === 0}
                                style={{fontSize: "14px"}}
                            />
                        ) : (
                            <Loader height={"100%"}/>
                        )}
                    </div>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            className={"button-standard button-white"}
                            onClick={() => {
                                setToggleDialogOpen(false);
                            }}>
                            Cancel
                        </Button>
                        <Button
                            // disabled={disableSubmit}
                            className={"button-standard button-submit"}
                            onClick={() => sendDataFile(segment_id, profile_data_obsolete)}>
                            Submit
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <div className="consumer-report-dashboard sticky">
                <div className="report-dashboard segment-name">
                    <button
                        onClick={backToDashboard}
                        type="button"
                        className="generateBtn mr-1 btn btn-rounded btn-primary bnt-block custom-btn"
                    >
                        Back
                    </button>
                    {location.state.segment_name}
                </div>
                <div className="color-dropdown prevent-split">
                    <span>Change Theme:&nbsp;</span>
                    <DropDownList
                        className={"color-dropdown-component"}
                        data={chartInfo.ConsumerThemeDictionary}
                        textField="name"
                        dataItemKey="id"
                        defaultValue={chartInfo.ConsumerThemeDictionary[0]}
                        onChange={handleAllChartThemeChange}
                        style={{width: "160px"}}
                    />
                </div>
                <div className="report button-group">
                    <button
                        onClick={() => pdfFileDownload(segment_id, profile_data_obsolete)}
                        type="button"
                        className="generateBtn mr-1 btn btn-rounded btn-primary bnt-block custom-btn"
                    >
                        Generate PDF
                    </button>

                    <button
                        type="button"
                        className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"
                        data-bs-toggle="modal"
                        data-bs-target=".bd-example-modal-lg"
                    >
                        Edit Profile
                    </button>
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"*/}
                    {/*    onClick={jsonFileDownload}*/}
                    {/*>*/}
                    {/*    <i className="fa fa-arrow-down"></i> JSON*/}
                    {/*</button>*/}
                    <button
                        type="button"
                        className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"
                        onClick={toggleFileFormatDownloadDialogue}
                    >
                        <i className="fa fa-arrow-down"></i> Data
                    </button>
                </div>
            </div>
            <div className="reports-display-content">
                {/* {location.state && (

        )} */}

                <div
                    className="modal fade bd-example-modal-lg"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Profile</h5>
                                <button type="button" className="close" data-bs-dismiss="modal">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 logo-section">*/}
                                    {/*    <div className="edit-dialogue-labels">Customize Report Logo</div>*/}
                                    {/*    <div className="indent-wrapper-logo">*/}
                                    {/*        <div className="form-check mb-2">*/}
                                    {/*            <input*/}
                                    {/*                type="checkbox"*/}
                                    {/*                id="defaultlogo"*/}
                                    {/*                className="form-check-input k-checkbox"*/}
                                    {/*                defaultChecked="true"*/}
                                    {/*                onClick={(e) => handleLogoCheckbox(e)}*/}
                                    {/*            />*/}
                                    {/*            <label className="form-check-label">*/}
                                    {/*                Use Default Logo*/}
                                    {/*            </label>*/}
                                    {/*            <img*/}
                                    {/*                className="logo-default-display"*/}
                                    {/*                src={defaultFile}*/}
                                    {/*                alt=""*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*        <form onSubmit={(e) => handleSubmit(e)}>*/}
                                    {/*            <div className="input-group">*/}
                                    {/*                <input*/}
                                    {/*                    type="file"*/}
                                    {/*                    id="uploadedlogo"*/}
                                    {/*                    className="form-control input-rounded"*/}
                                    {/*                    placeholder="file name"*/}
                                    {/*                    aria-label="Upload Logo"*/}
                                    {/*                    aria-describedby="basic-addon2"*/}
                                    {/*                    onChange={onFileChange}*/}
                                    {/*                />*/}
                                    {/*                {selectedFile != null && (*/}
                                    {/*                    <img*/}
                                    {/*                        id={"uploaded-logo-img"}*/}
                                    {/*                        className="custom-logo-display"*/}
                                    {/*                        src={selectedFile}*/}
                                    {/*                        alt=""*/}
                                    {/*                    />*/}
                                    {/*                )}*/}
                                    {/*            </div>*/}
                                    {/*        </form>*/}
                                    {/*        <div className="logo-tooltip"  style={{paddingBottom: "10px"}}>*/}
                                    {/*        For best result, use picture that is width:height = 8:1.*/}
                                    {/*        Max File Size is 5 MB. Allowed File Types are jpeg, jpg,*/}
                                    {/*        png, svg. Logos saved in this edit dialogue can be found in the dropdown list in the future. Last 5 items will be saved.*/}
                                    {/*        </div>*/}
                                    {/*        <div className="input-group">*/}
                                    {/*            <DropDownList*/}
                                    {/*                style={{*/}
                                    {/*                    width: "330px",*/}
                                    {/*                }}*/}
                                    {/*                id={"logo-history-dropdown"}*/}
                                    {/*                data={imageHistoryData === null ? []:imageHistoryData}*/}
                                    {/*                defaultItem={logoHistDefaultItem}*/}
                                    {/*                onChange={onImgHistListChange}*/}
                                    {/*                textField="text"*/}
                                    {/*            />*/}
                                    {/*            {historyLogoImage != null &&*/}
                                    {/*                <img*/}
                                    {/*                    id={"history-logo-img"}*/}
                                    {/*                    className="custom-logo-display"*/}
                                    {/*                    src={historyLogoImage}*/}
                                    {/*                    alt=""*/}
                                    {/*                    style={{height: "30px"}}*/}
                                    {/*                />}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                                    {/*    <div className="edit-dialogue-labels">Profile Report Title</div>*/}
                                    {/*    <div className="indent-wrapper">*/}
                                    {/*        <div className="form-group">*/}
                                    {/*            <input*/}
                                    {/*                type="text"*/}
                                    {/*                className="form-control input-rounded title"*/}
                                    {/*                placeholder="Report Title"*/}
                                    {/*                value={reportTitle}*/}
                                    {/*                onChange={(e) => setReportTitle(e.target.value)}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                                    {/*    <div className="edit-dialogue-labels">Report Footer</div>*/}
                                    {/*    <div className="indent-wrapper">*/}
                                    {/*        <div className="form-group">*/}
                                    {/*            <input*/}
                                    {/*                type="text"*/}
                                    {/*                className="form-control input-rounded"*/}
                                    {/*                placeholder="Change Footer Here"*/}
                                    {/*                value={reportFooter}*/}
                                    {/*                onChange={(e) => setReportFooter(e.target.value)}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                                    {/*    <div className="edit-dialogue-labels">Profile Report Date</div>*/}
                                    {/*    <div className="indent-wrapper">*/}
                                    {/*        <div className="form-group">*/}
                                    {/*            <DatePicker*/}
                                    {/*                selected={startDate}*/}
                                    {/*                onChange={(date) => setStartDate(date)}*/}
                                    {/*                className="form-control input-rounded report-date"*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="include-exclude-outerwrapper">
                                        <div className="include-exclude-section-wrapper">
                                            <div className="include-exclude-header edit-dialogue-labels">
                                                <div className="label-option">
                                                    <label>Include/Exclude Items</label>
                                                </div>
                                                <div className="label-select-all">
                                                    <input
                                                        type="checkbox"
                                                        className="selectall-checkbox form-check-input k-checkbox"
                                                        id={"selectAll"}
                                                        value={selectAll}
                                                        onClick={(e) => {
                                                            handleSelectAllFn(e)
                                                        }}
                                                        defaultChecked="true"
                                                    />
                                                    <label>Select All</label>
                                                </div>
                                            </div>
                                            <div className="indent-wrapper">
                                                <div className="include-exclude-wrapper">

                                                    {groups.length > 0 &&
                                                        groups?.map((items, groupIndex) => {
                                                            return (
                                                                <div className="form-group">
                                                                    {items?.map((item, itemIndex) => {
                                                                        return (
                                                                            <div className="form-check mb-2">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input k-checkbox"
                                                                                    id={"select" + groupIndex + itemIndex}
                                                                                    value={item}
                                                                                    onClick={(e) => {
                                                                                        handleSelectFn(e);
                                                                                    }}
                                                                                    defaultChecked="true"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    for={"select" + groupIndex + itemIndex}
                                                                                >
                                                                                    {item}
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="include-exclude-section-wrapper">
                                            <div className="edit-dialogue-labels">
                                                Rearrange Chart Order
                                                <button
                                                    type="button"
                                                    className="reset-btn btn"
                                                    onClick={handleResetFn}
                                                >
                                                    <i className="fa fa-solid fa-rotate-right"/>
                                                </button>
                                            </div>
                                            <div className="rearrange-wrapper pb-3">
                                                <div className="indent-wrapper">
                                                    <div className="sortable-list">
                                                        <Sortable
                                                            idField={"id"}
                                                            data={sortable}
                                                            itemUI={SortableItemUI}
                                                            onDragOver={onDragOver}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-3">*/}
                                    {/*    <div className="edit-dialogue-labels">Change PDF Font Size</div>*/}
                                    {/*    <div className="indent-wrapper">*/}
                                    {/*        <Slider*/}
                                    {/*            buttons={true}*/}
                                    {/*            step={7}*/}
                                    {/*            defaultValue={14}*/}
                                    {/*            min={7}*/}
                                    {/*            max={21}*/}
                                    {/*            width={220}*/}
                                    {/*            onChange={changeStyle}*/}
                                    {/*        >*/}
                                    {/*            <SliderLabel position={7}>Small</SliderLabel>*/}
                                    {/*            <SliderLabel position={14}>Medium</SliderLabel>*/}
                                    {/*            <SliderLabel position={21}>Large</SliderLabel>*/}
                                    {/*        </Slider>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-3">
                                        <div className="edit-dialogue-labels">Notes</div>
                                        <div className="indent-wrapper">
                                        <textarea
                                            className="form-control input-rounded notes"
                                            placeholder="Report Notes"
                                            rows={7}
                                            cols={40}
                                            value={reportNotes}
                                            onChange={(e) => setReportNotes(e.target.value)}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn light btn-primary btn-rounded "
                                    data-bs-dismiss="modal"
                                    onClick={cancelChanges}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-rounded btn-primary bnt-block"
                                    data-bs-dismiss="modal"
                                    onClick={saveChanges}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report container-fluid">
                    <PDFExport
                        ref={pdfExportComponent}
                        scale={0.43}
                        paperSize="A4"
                        multiPage={true}
                        // margin={{top: 35, left: 0, right: 0, bottom: 35}}
                        // margin="0.5in"
                        keepTogether=".prevent-split"
                        forcePageBreak=".page-break"
                        title="Activate Segment Report"
                        reportTitle="The Consumer report"
                        reportLogo={reportLogo}
                        startDate={startDate}
                        pageTemplate={PageTemplate}
                        fileName={
                            location.state.segment_name +
                            "_" +
                            new Date().getFullYear() +
                            (new Date().getMonth() + 1) +
                            new Date().getDate() +
                            "_" +
                            Math.floor(Date.now() / 1000)
                        }
                    >
                        <PDFMargin top="12mm" bottom="30mm"/>
                        <div
                            className="row pdf-data consumer-reports-data-content"
                            id="container"
                            ref={containerRef}
                        >
                            <div id="stylesHolder">
                                <style>{styles}</style>
                            </div>
                            {chartRelatedData["Activate Segment Summary"] &&
                                chartRelatedData["Activate Segment Summary"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Activate Segment Summary")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center prevent-split">
                                                    <h4>Activate Segment Summary</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card-body-ActivateSegmentSummary prevent-split">
                                                            <div className="table-responsive prevent-split">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                    <tbody>
                                                                    {Object.keys(
                                                                        chartRelatedData[
                                                                            "Activate Segment Summary"
                                                                            ][0].rows
                                                                    ).sort((a, b) => sortingArr1.indexOf(a) - sortingArr1.indexOf(b)).map(function (key) {
                                                                        return (
                                                                            <tr>
                                                                                <td>{key}</td>
                                                                                <td className="right">
                                                                                    <NumberFormat
                                                                                        thousandsGroupStyle="thousand"
                                                                                        value={
                                                                                            chartRelatedData[
                                                                                                "Activate Segment Summary"
                                                                                                ][0].rows[key]
                                                                                        }
                                                                                        decimalSeparator="."
                                                                                        displayType="text"
                                                                                        type="text"
                                                                                        thousandSeparator={true}
                                                                                        allowNegative={true}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card-body-ActivateSegmentSummary prevent-split">
                                                            <div className="table-responsive prevent-split">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                    <tbody>
                                                                    {Object.keys(
                                                                        chartRelatedData[
                                                                            "Activate Segment Summary"
                                                                            ][1].rows
                                                                    ).sort((a, b) => sortingArr2.indexOf(a) - sortingArr2.indexOf(b)).map(function (key) {
                                                                        return (
                                                                            <tr>
                                                                                <td>{key}</td>
                                                                                <td className="right">
                                                                                    <NumberFormat
                                                                                        thousandsGroupStyle="thousand"
                                                                                        value={
                                                                                            chartRelatedData[
                                                                                                "Activate Segment Summary"
                                                                                                ][1].rows[key]
                                                                                        }
                                                                                        decimalSeparator="."
                                                                                        displayType="text"
                                                                                        type="text"
                                                                                        thousandSeparator={true}
                                                                                        allowNegative={true}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}


                            {chartRelatedData["Location"] &&
                                chartRelatedData["Location"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Location")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center prevent-split">
                                                    <h4>Location</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card prevent-split">
                                                            <div className="card-body prevent-split">
                                                                <div className="table-responsive prevent-split">
                                                                    <table
                                                                        className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Location"].rows.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card prevent-split">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Pie
                                                                    ref={locationPieRef}
                                                                    id="locationPie"
                                                                    data={chartInfo.locationData}
                                                                    width={500}
                                                                    height={400}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        radius: "70%",
                                                                        spacing: 0,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Digital Activity"] &&
                                chartRelatedData["Digital Activity"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Digital Activity")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center prevent-split">
                                                    <h4>Digital Activity</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card prevent-split">
                                                            <div className="card-body prevent-split">
                                                                <div className="table-responsive prevent-split">
                                                                    <table
                                                                        className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Digital Activity"].rows.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card prevent-split">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Pie
                                                                    ref={digitalActivityPieRef}
                                                                    id="digitalActivityPie"
                                                                    data={chartInfo.digitalActivityData}
                                                                    width={500}
                                                                    height={400}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        radius: "70%",
                                                                        spacing: 0,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Social Matrix"] &&
                                chartRelatedData["Social Matrix"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Social Matrix")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center prevent-split">
                                                    <h4>Social Matrix</h4>
                                                </div>
                                                <div className="row prevent-split">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card prevent-split">
                                                            <div className="card-body prevent-split">
                                                                <div className="table-responsive prevent-split">
                                                                    <table
                                                                        className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="text-nowrap col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Social Matrix"].rows
                                                                            .slice(0, 12)
                                                                            .map((item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {item["+/- Avg"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {" "}
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card prevent-split">
                                                            <div className="card-body prevent-split">
                                                                <div className="table-responsive prevent-split">
                                                                    <table
                                                                        className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Social Matrix"].rows
                                                                            .slice(12, 25)
                                                                            .map((item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {item["+/- Avg"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {" "}
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center prevent-split">
                                                    <div
                                                        className="mx-auto col-xl-7 col-lg-7 col-md-7 col-sm-7 prevent-split">
                                                        <div className="card prevent-split">
                                                            <div className="card-body-graphs prevent-split social-matrix">
                                                                <Radar
                                                                    ref={socialMatrixRef}
                                                                    id={'socialRadar'}
                                                                    data={chartInfo.socialMatrixData}
                                                                    width={700}
                                                                    height={700}
                                                                    options={{
                                                                        scales: {
                                                                            r: {
                                                                                pointLabels: {
                                                                                    font: {
                                                                                        size: 16,
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                    }}
                                                                    // plugins={[radarPlugin]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Age"] && chartRelatedData["Age"].active && (
                                <>
                                    <div
                                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                        id={"div" + idFinder("Age")}
                                    >
                                        <div className="card prevent-split">
                                            <div className="text-center prevent-split">
                                                <h4>Age</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                    <div className="report-card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-xl-1 col-lg-1 col-md-1">
                                                                            <strong>Value</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Match %</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>+/- Avg</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>People</strong>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {chartRelatedData["Age"].rows.map(
                                                                        (item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {item["+/- Avg"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                    <div className="card prevent-split">
                                                        <div className="card-body-graphs prevent-split">
                                                            <Bar
                                                                ref={ageBarRef}
                                                                data={chartInfo.ageData}
                                                                width={1000}
                                                                height={500}
                                                                options={{
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                    spacing: 0,
                                                                    plugins: {
                                                                        legend: {
                                                                            display: true,
                                                                            labels: {
                                                                                font: {
                                                                                    size: 18,
                                                                                    weight: "bold",
                                                                                },
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}



                            {/*{chartRelatedData["Ethnicity"] &&*/}
                            {/*    chartRelatedData["Ethnicity"].active && (*/}
                            {/*        <>*/}
                            {/*            <div*/}
                            {/*                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"*/}
                            {/*                id={"div" + idFinder("Ethnicity")}*/}
                            {/*            >*/}
                            {/*                <div className="card prevent-split">*/}
                            {/*                    <div className="text-center">*/}
                            {/*                        <h4>Ethnicity</h4>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="row">*/}
                            {/*                        <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">*/}
                            {/*                            <div className="report-card">*/}
                            {/*                                <div className="card-body">*/}
                            {/*                                    <div className="table-responsive">*/}
                            {/*                                        <table*/}
                            {/*                                            className="table table-responsive-md table-striped">*/}
                            {/*                                            <thead>*/}
                            {/*                                            <tr>*/}
                            {/*                                                <th className="col-xl-4 col-lg-4 col-md-4">*/}
                            {/*                                                    <strong>Value</strong>*/}
                            {/*                                                </th>*/}
                            {/*                                                <th className="right col-xl-4 col-lg-4 col-md-4">*/}
                            {/*                                                    <strong>Match %</strong>*/}
                            {/*                                                </th>*/}
                            {/*                                                /!* <th className="col-xl-2 col-lg-2 col-md-2"></th> *!/*/}
                            {/*                                                <th className="right col-xl-4 col-lg-4 col-md-4">*/}
                            {/*                                                    <strong>People</strong>*/}
                            {/*                                                </th>*/}
                            {/*                                            </tr>*/}
                            {/*                                            </thead>*/}
                            {/*                                            <tbody>*/}
                            {/*                                            {chartRelatedData["Ethnicity"].rows.map(*/}
                            {/*                                                (item, i) => {*/}
                            {/*                                                    return (*/}
                            {/*                                                        <tr>*/}
                            {/*                                                            <td>{item["Value"]}</td>*/}
                            {/*                                                            <td className="right">*/}
                            {/*                                                                {item["Match %"]} %*/}
                            {/*                                                            </td>*/}
                            {/*                                                            <td className="right">*/}
                            {/*                                                                {" "}*/}
                            {/*                                                                <NumberFormat*/}
                            {/*                                                                    thousandsGroupStyle="thousand"*/}
                            {/*                                                                    value={item["People"]}*/}
                            {/*                                                                    decimalSeparator="."*/}
                            {/*                                                                    displayType="text"*/}
                            {/*                                                                    type="text"*/}
                            {/*                                                                    thousandSeparator={true}*/}
                            {/*                                                                    allowNegative={true}*/}
                            {/*                                                                />*/}
                            {/*                                                            </td>*/}
                            {/*                                                        </tr>*/}
                            {/*                                                    );*/}
                            {/*                                                }*/}
                            {/*                                            )}*/}
                            {/*                                            </tbody>*/}
                            {/*                                        </table>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">*/}
                            {/*                            <div className="card">*/}
                            {/*                                <div className="card-body-graphs prevent-split">*/}
                            {/*                                    <Bar*/}
                            {/*                                        ref={ethnicityBarRef}*/}
                            {/*                                        data={chartInfo.ethnicityData}*/}
                            {/*                                        width={700}*/}
                            {/*                                        height={500}*/}
                            {/*                                        options={{*/}
                            {/*                                            indexAxis: "y",*/}
                            {/*                                            plugins: {*/}
                            {/*                                                legend: {*/}
                            {/*                                                    display: false,*/}
                            {/*                                                    labels: {*/}
                            {/*                                                        font: {*/}
                            {/*                                                            size: 18,*/}
                            {/*                                                            weight: "bold",*/}
                            {/*                                                        },*/}
                            {/*                                                    },*/}
                            {/*                                                },*/}
                            {/*                                            },*/}
                            {/*                                            responsive: true,*/}
                            {/*                                            maintainAspectRatio: false,*/}
                            {/*                                        }}*/}
                            {/*                                    />*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )}*/}

                            {chartRelatedData["Political Party"] &&
                                chartRelatedData["Political Party"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Political Party")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Political Party</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Political Party"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {" "}
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Pie
                                                                    ref={politicalPieRef}
                                                                    data={chartInfo.politicalPartyData}
                                                                    width={500}
                                                                    height={400}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        radius: "70%",
                                                                        spacing: 0,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Income Range"] &&
                                chartRelatedData["Income Range"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Income Range")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Income Range</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-1 col-lg-1 col-md-1" /> */}
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Income Range"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {item["+/- Avg"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {" "}
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={incomeRangeRef}
                                                                    data={chartInfo.incomeRangeData}
                                                                    width={700}
                                                                    height={600}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Charitable Contributions Decile"] &&
                                chartRelatedData["Charitable Contributions Decile"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Charitable Contributions Decile")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Charitable Contributions Decile</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Charitable Contributions Decile"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {item["+/- Avg"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {" "}
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={charContributionsRef}
                                                                    data={
                                                                        chartInfo.charitableContributionDecileData
                                                                    }
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Home Value"] &&
                                chartRelatedData["Home Value"].active && (
                                    <>
                                        <div
                                            className="prevent-split col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                            id={"div" + idFinder("Home Value")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Home Value</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Home Value"].rows.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {item["+/- Avg"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {" "}
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={homeValRef}
                                                                    data={chartInfo.homeValueData}
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Discretionary Income Decile"] &&
                                chartRelatedData["Discretionary Income Decile"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Discretionary Income Decile")}
                                        >
                                            <div className="prevent-split card">
                                                <div className="text-center">
                                                    <h4>Discretionary Income Decile</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Discretionary Income Decile"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {item["+/- Avg"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {" "}
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={disIncomeRef}
                                                                    data={chartInfo.discretionaryData}
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Net Worth"] &&
                                chartRelatedData["Net Worth"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Net Worth")}
                                        >
                                            <div className="prevent-split card">
                                                <div className="text-center">
                                                    <h4>Net Worth</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Net Worth"].rows.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Value"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {item["+/- Avg"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            {" "}
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["People"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs">
                                                                <Line
                                                                    ref={netWorthRef}
                                                                    data={chartInfo.netWorthData}
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Invested Assets Decile"] &&
                                chartRelatedData["Invested Assets Decile"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Invested Assets Decile")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Invested Assets Decile</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>+/- Avg</strong>
                                                                            </th>
                                                                            <th className="right col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Invested Assets Decile"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {item["+/- Avg"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        {" "}
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={investedAssetsRef}
                                                                    data={chartInfo.investedAssetsData}
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Genders Present"] &&
                                chartRelatedData["Genders Present"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Genders Present")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Genders Present</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Genders Present"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Pie
                                                                    ref={genderPieRef}
                                                                    data={chartInfo.gendersPresentData}
                                                                    width={500}
                                                                    height={400}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        radius: "70%",
                                                                        spacing: 0,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            {chartRelatedData["Number of Children"] &&
                                chartRelatedData["Number of Children"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Number of Children")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Number of Children</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Value</strong>
                                                                            </th>
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            <th className="right col-xl-4 col-lg-4 col-md-4">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Number of Children"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Value"]}</td>
                                                                                    <td className="right">
                                                                                        {item["Match %"]} %
                                                                                    </td>
                                                                                    <td className="right">
                                                                                        <NumberFormat
                                                                                            thousandsGroupStyle="thousand"
                                                                                            value={item["People"]}
                                                                                            decimalSeparator="."
                                                                                            displayType="text"
                                                                                            type="text"
                                                                                            thousandSeparator={true}
                                                                                            allowNegative={true}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Bar
                                                                    ref={childrenBarRef}
                                                                    data={chartInfo.numberOfChildrenData}
                                                                    width={500}
                                                                    height={500}
                                                                    options={{
                                                                        indexAxis: "y",
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: false,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Top Contextual Samples"] &&
                                chartRelatedData["Top Contextual Samples"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Top Contextual Samples")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Top Contextual Samples</h4>
                                                </div>
                                                <div className="row consumer-topcontextual prevent-split">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-1 col-lg-1 col-md-1">
                                                                                <strong>Rank</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Sites</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Places</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Organizations</strong>
                                                                            </th>
                                                                            <th className="white-space col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Categories</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Top Contextual Samples"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Rank"]}</td>
                                                                                    <td>{item["Sites"]}</td>
                                                                                    <td>{item["People"]}</td>
                                                                                    <td>{item["Places"]}</td>
                                                                                    <td>{item["Organizations"]}</td>
                                                                                    <td>{item["Categories"]}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Top Contextual Sample Counts"] &&
                                chartRelatedData["Top Contextual Sample Counts"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Top Contextual Sample Counts")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Top Contextual Sample Counts</h4>
                                                </div>
                                                <div className="row consumer-topcontextual prevent-split">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-1 col-lg-1 col-md-1">
                                                                                <strong>Rank</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Sites</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>People</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Places</strong>
                                                                            </th>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Organizations</strong>
                                                                            </th>
                                                                            <th className="white-space col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Categories</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Top Contextual Sample Counts"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Rank"]}</td>
                                                                                    <td>{item["Sites"]}</td>
                                                                                    <td>{item["People"]}</td>
                                                                                    <td>{item["Places"]}</td>
                                                                                    <td>{item["Organizations"]}</td>
                                                                                    <td>{item["Categories"]}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Top 20 Consumer Titles"] &&
                                chartRelatedData["Top 20 Consumer Titles"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Top 20 Consumer Titles")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center">
                                                    <h4>Top Consumer Titles</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">
                                                        <div className="report-card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-responsive-md table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Rank</strong>
                                                                            </th>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Category</strong>
                                                                            </th>
                                                                            <th className="col-xl-3 col-lg-3 col-md-3">
                                                                                <strong>Title</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData[
                                                                            "Top 20 Consumer Titles"
                                                                            ].rows.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["Rank"]}</td>
                                                                                    <td>{item["Category"]}</td>
                                                                                    <td>{item["Title"]}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {chartRelatedData["Audience Supply Path Optimization"] &&
                                chartRelatedData["Audience Supply Path Optimization"].active && (
                                    <>
                                        <div
                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                            id={"div" + idFinder("Audience Supply Path Optimization")}
                                        >
                                            <div className="card prevent-split">
                                                <div className="text-center prevent-split">
                                                    <h4>Audience Supply Path Optimization</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                        <div className="report-card prevent-split">
                                                            <div className="card-body prevent-split">
                                                                <div className="table-responsive prevent-split">
                                                                    <table
                                                                        className="text-nowrap table table-responsive-md table-striped prevent-split">
                                                                        <thead>
                                                                        <tr>
                                                                            <th className="col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Platform</strong>
                                                                            </th>
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Match %</strong>
                                                                            </th>
                                                                            {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                                                                            <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                                <strong>Locations</strong>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {chartRelatedData["Audience Supply Path Optimization"].rows.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item["Platform"]}</td>
                                                                                        <td className="right">
                                                                                            {item["Match %"]} %
                                                                                        </td>
                                                                                        <td className="right">
                                                                                            <NumberFormat
                                                                                                thousandsGroupStyle="thousand"
                                                                                                value={item["Locations"]}
                                                                                                decimalSeparator="."
                                                                                                displayType="text"
                                                                                                type="text"
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 prevent-split">
                                                        <div className="card prevent-split">
                                                            <div className="card-body-graphs prevent-split">
                                                                <Pie
                                                                    ref={audiencePieRef}
                                                                    id="audiencePie"
                                                                    data={chartInfo.audienceSupplyPathOptData}
                                                                    width={500}
                                                                    height={400}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        radius: "70%",
                                                                        spacing: 0,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: true,
                                                                                labels: {
                                                                                    font: {
                                                                                        size: 18,
                                                                                        weight: "bold",
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {/*<>*/}
                            {/*    {chartRelatedData["Bubble Map"] &&*/}
                            {/*        chartRelatedData["Bubble Map"].active && (*/}
                            {/*            <div*/}
                            {/*                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"*/}
                            {/*                id={"div" + idFinder("Bubble Map")}*/}
                            {/*            >*/}
                            {/*                <div className="card prevent-split">*/}
                            {/*                    <div className="text-center">*/}
                            {/*                        <h4>Bubble Map</h4>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="row">*/}
                            {/*                        <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">*/}
                            {/*                            <div className="report-card">*/}
                            {/*                                <div className="card-body">*/}
                            {/*                                    <div className="table-responsive">*/}
                            {/*                                        <div className="card-body-graphs-map prevent-split">*/}
                            {/*                                            <Map center={center} zoom={6}>*/}
                            {/*                                                <MapLayers>*/}
                            {/*                                                    <MapTileLayer*/}
                            {/*                                                        urlTemplate={tileUrl}*/}
                            {/*                                                        subdomains={tileSubdomains}*/}
                            {/*                                                        attribution={attribution}*/}
                            {/*                                                        zIndex={0}*/}
                            {/*                                                    />*/}
                            {/*                                                    <MapBubbleLayer*/}
                            {/*                                                        data={convertedBubbleLocations}*/}
                            {/*                                                        locationField="location"*/}
                            {/*                                                        valueField="cnt"*/}
                            {/*                                                        attribution=""*/}
                            {/*                                                        style={bubbleStyle}*/}
                            {/*                                                        zIndex={1}*/}
                            {/*                                                    >*/}
                            {/*                                                        <MapBubbleLayerTooltip*/}
                            {/*                                                            render={renderBubbleTooltip}/>*/}
                            {/*                                                    </MapBubbleLayer>*/}
                            {/*                                                </MapLayers>*/}
                            {/*                                            </Map>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*</>*/}
                            <>
                                {/*{chartRelatedData["Marker Map"] &&*/}
                                {/*    chartRelatedData["Marker Map"].active && (*/}
                                {/*        <div*/}
                                {/*            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"*/}
                                {/*            id={"div" + idFinder("Marker Map")}*/}
                                {/*        >*/}
                                {/*            <div className="card prevent-split">*/}
                                {/*                <div className="text-center">*/}
                                {/*                    <h4>Marker Map</h4>*/}
                                {/*                </div>*/}
                                {/*                <div className="row">*/}
                                {/*                    <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">*/}
                                {/*                        <div className="report-card">*/}
                                {/*                            <div className="card-body">*/}
                                {/*                                <div className="table-responsive">*/}
                                {/*                                    <div className="card-body-graphs-map prevent-split">*/}
                                {/*                                        <MapComponent />*/}
                                {/*                                    </div>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                            </>
                            {/*{chartRelatedData["Map"] &&*/}
                            {/*    chartRelatedData["Map"].active && (*/}
                            {/*        <>*/}
                            {/*        <div*/}
                            {/*            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split report-map"*/}
                            {/*            id={"div" + idFinder("Map")}*/}
                            {/*        >*/}
                            {/*            <div className="card prevent-split">*/}
                            {/*                <div className="text-center">*/}
                            {/*                    <h4>Map</h4>*/}
                            {/*                </div>*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-xl-12 col-lg-12 col-md-12 prevent-split">*/}
                            {/*                        <div className="report-card">*/}
                            {/*                            <div className="card-body">*/}
                            {/*                                <div className="table-responsive">*/}
                            {/*                                    <div className="card-body-graphs-map prevent-split">*/}
                            {/*                                        <MapComponent />*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {chartRelatedData["People Top Zip5s"] && chartRelatedData["People Top Zip5s"].active && (
                                <>
                                    <div
                                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                        id={"div" + idFinder("People Top Zip5s")}
                                    >
                                        <div className="card prevent-split">
                                            <div className="text-center prevent-split">
                                                <h4>Top Zip5s</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                    <div className="report-card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-xl-1 col-lg-1 col-md-1">
                                                                            <strong>Zip5</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Audience</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Zip5 Population</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Percentage</strong>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {chartRelatedData["People Top Zip5s"].rows.slice(0, 10).map(
                                                                        (item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["zip5"]}</td>
                                                                                    <td className="right">{item["audience_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["zip5_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["pct"]}%</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={chartRelatedData["People Top Zip5s"].rows.length > 10 ? `col-xl-6 col-lg-6 col-md-6 prevent-split`: `d-none`}>
                                                    <div className="report-card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-xl-1 col-lg-1 col-md-1">
                                                                            <strong>Zip5</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Audience</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Zip5 Population</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Percentage</strong>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {chartRelatedData["People Top Zip5s"].rows.slice(10).map(
                                                                        (item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["zip5"]}</td>
                                                                                    <td className="right">{item["audience_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["zip5_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["pct"]}%</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={chartRelatedData["People Top Zip5s"].rows.length > 10 ? `col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split` : `col-xl-6 col-lg-6 col-md-6 prevent-split`}>
                                                    <div className="card prevent-split zip5">
                                                        <div className="card-body-graphs prevent-split">
                                                            <Bar
                                                                ref={topZip5sRef}
                                                                data={chartInfo.topZip5sData}
                                                                width={700}
                                                                height={600}
                                                                options={{
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                    spacing: 0,
                                                                    plugins: {
                                                                        legend: {
                                                                            display: true,
                                                                            labels: {
                                                                                font: {
                                                                                    size: 18,
                                                                                    weight: "bold",
                                                                                },
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {chartRelatedData["People Top States"] && chartRelatedData["People Top States"].active && (
                                <>
                                    <div
                                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                        id={"div" + idFinder("People Top States")}
                                    >
                                        <div className="card prevent-split">
                                            <div className="text-center prevent-split">
                                                <h4>Top States</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 prevent-split">
                                                    <div className="report-card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-xl-1 col-lg-1 col-md-1">
                                                                            <strong>State</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Audience</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>State Population</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Percentage</strong>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {chartRelatedData["People Top States"].rows.slice(0, 10).map(
                                                                        (item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["state"]}</td>
                                                                                    <td className="right">{item["audience_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["state_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["pct"]}%</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={chartRelatedData["People Top States"].rows.length > 10 ? `col-xl-6 col-lg-6 col-md-6 prevent-split`: `d-none`}>
                                                    <div className="report-card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="text-nowrap table table-responsive-md table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-xl-1 col-lg-1 col-md-1">
                                                                            <strong>State</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Audience</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>State Population</strong>
                                                                        </th>
                                                                        <th className="right col-xl-2 col-lg-2 col-md-2">
                                                                            <strong>Percentage</strong>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {chartRelatedData["People Top States"].rows.slice(10).map(
                                                                        (item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item["state"]}</td>
                                                                                    <td className="right">{item["audience_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["state_people"].toLocaleString()}</td>
                                                                                    <td className="right">{item["pct"]}%</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={chartRelatedData["People Top States"].rows.length > 10 ? `col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split` : `col-xl-6 col-lg-6 col-md-6 prevent-split`}>
                                                    <div className="card prevent-split top-states">
                                                        <div className="card-body-graphs prevent-split">
                                                            <Bar
                                                                ref={topStatesRef}
                                                                data={chartInfo.topStatesData}
                                                                width={700}
                                                                height={600}
                                                                options={{
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                    spacing: 0,
                                                                    plugins: {
                                                                        legend: {
                                                                            display: true,
                                                                            labels: {
                                                                                font: {
                                                                                    size: 18,
                                                                                    weight: "bold",
                                                                                },
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {chartRelatedData["Notes"] &&
                                chartRelatedData["Notes"].active && (
                                    <>
                                        <div className={"prevent-split"}>
                                            <div
                                                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split"
                                                id={"div" + idFinder("Notes")}
                                            >
                                                <div className="card prevent-split">
                                                    <div className="text-center prevent-split">
                                                        <h4>Notes</h4>
                                                    </div>
                                                    <div className="row prevent-split">
                                                        <div
                                                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split">
                                                            <div className="report-card prevent-split bg-white">
                                                                <div
                                                                    className="card-body consumer-dashboard-notes prevent-split bg-white">
                                                                    <p className="prevent-split bg-white">
                                                                        {reportNotes}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    </PDFExport>
                    <div className="row">
                        <div className="col-lg-12">
                            {!location.state && (
                                <div className="card">No Chart data available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
